/**
 *
 * SettingsPage
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Link,
  useLocation,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import { translations } from 'locales/i18n';
import { settingsPageSelector } from './selectors';
import { Resources } from './types';

import { Box, AppBar, Tabs, Tab, Paper } from '@material-ui/core';
import { UsersManager } from './UsersManager';
import { AddOrUpdateDialog } from './AddOrUpdateDialog';
import { UnifilaireManager } from './UnifilaireManager';
import { appDataSelector } from 'app/selectors';

const resourcesManager: {
  [key in Exclude<Resources, Resources.LISTS>]: () => JSX.Element;
} = {
  [Resources.USERS]: UsersManager,
  [Resources.UNIFILAIRES]: UnifilaireManager,
};

export const SettingsPage = () => {
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();
  const { isSuperadmin } = useSelector(appDataSelector);
  const { resourceDialogParams } = useSelector(settingsPageSelector);
  let resources = Object.values(
    isSuperadmin
      ? [Resources.USERS, Resources.UNIFILAIRES]
      : [Resources.UNIFILAIRES],
  );

  const [currentTab, setCurrentTab] = React.useState(
    isSuperadmin ? Resources.USERS : Resources.UNIFILAIRES,
  );

  React.useEffect(() => {
    const resource = pathname.split('/').slice(-1)[0] as Resources;
    resources.includes(resource) && setCurrentTab(resource);
  }, [pathname]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow="1"
        style={{ marginTop: '20px' }}
      >
        <AppBar position="static">
          <Tabs value={currentTab} variant="scrollable" scrollButtons="on">
            {resources.map((resource) => (
              <Tab
                value={resource}
                label={t(translations.Tabs[resource])}
                component={Link}
                to={`${url}/${resource}`}
                key={`tabs-router-${resource}`}
                id={`tabs-router-${resource}`}
                aria-controls={`tabs-router-${resource}`}
              />
            ))}
          </Tabs>
        </AppBar>

        <Box
          style={{
            width: '100%',
            padding: '20px',
            borderRadius: '0 0 4px 4px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          component={Paper}
        >
          <Switch>
            {resources.map((resource) => (
              <Route key={`tabs-${resource}`} path={`${path}/${resource}`}>
                <Box component={resourcesManager[resource]} />
              </Route>
            ))}
            <Redirect
              to={`${path}/${
                isSuperadmin ? Resources.USERS : Resources.UNIFILAIRES
              }`}
            />
          </Switch>
        </Box>
      </Box>

      <AddOrUpdateDialog {...resourceDialogParams} />
    </>
  );
};
