/**
 *
 * NavBar
 *
 */

import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';

import {
  DashboardRounded,
  SettingsRounded,
  PersonRounded,
  ExitToAppRounded,
  CloudOffRounded,
} from '@material-ui/icons';
import { Box, Divider, withTheme } from '@material-ui/core';
import { NavButton } from './components/NavButton';

interface Props {
  onOpenProfileDialogClick: () => void;
  order: number;
  offline: boolean;
}

export const NavBar = ({ onOpenProfileDialogClick, order, offline }: Props) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { onSmallDevice, isAdmin, isSuperadmin } = useSelector(appDataSelector);
  const dividerIndex = 2;
  const navLinks = [
    {
      path: '/',
      tooltip: t(translations.NavBar.dashboard.tooltip),
      Icon: DashboardRounded,
      visibleOffline: true,
    },
    ...(!onSmallDevice && (isAdmin || isSuperadmin)
      ? [
          {
            path: '/settings',
            tooltip: t(translations.NavBar.settings.tooltip),
            Icon: SettingsRounded,
          },
        ]
      : []),

    {
      tooltip: t(translations.NavBar.profile.tooltip),
      Icon: PersonRounded,
      onClick: () => onOpenProfileDialogClick(),
      visibleOffline: true,
    },
    {
      tooltip: t(translations.NavBar.logout.tooltip),
      Icon: ExitToAppRounded,
      onClick: () => logout(),
      className: 'logout',
      divider: true,
    },
  ];

  return (
    <NavContainer
      display="flex"
      justifyContent="center"
      alignItems="center"
      order={order}
    >
      <NavBox display="flex" flexDirection={onSmallDevice ? 'row' : 'column'}>
        {_.compact(
          navLinks.map(({ divider, visibleOffline, ...navLink }, index) =>
            !offline || visibleOffline ? (
              <React.Fragment key={`navlink-${index}`}>
                {/* {!onSmallDevice && divider && <StyledDiviver />} */}
                <NavButton {...navLink} />
              </React.Fragment>
            ) : undefined,
          ),
        )}
      </NavBox>
    </NavContainer>
  );
};

const NavContainer = styled(Box)`
  margin: 10px;
`;

const NavBox = withTheme(styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 10px;
  padding: 10px;
  box-shadow: ${(props) => props.theme.shadows[4]};
`);

const StyledDiviver = withTheme(styled(Divider)`
  background-color: ${(props) => props.theme.palette.grey['200']} !important;
  margin: 20px 0 !important;
`);
