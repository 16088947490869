import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  children: React.ReactNode;
}

interface ContextValue {
  offline: boolean;
  setOffline: Dispatch<SetStateAction<boolean>>;
  online: boolean;
  setOnline: Dispatch<SetStateAction<boolean>>;
}

export const NetworkContext = React.createContext<ContextValue>(
  {} as ContextValue,
);

export const NetworkProvider = ({ children }: Props) => {
  const [offline, setOffline] = React.useState(false);
  const [online, setOnline] = React.useState(navigator.onLine);

  const setNetwork = (isOnline: boolean) => {
    setOnline(isOnline);
    setOffline(!isOnline);
  };

  React.useEffect(() => {
    window.addEventListener('online', (e) => setNetwork(true));
    window.addEventListener('offline', (e) => setNetwork(false));
    setNetwork(navigator.onLine);
  }, []);

  return (
    <NetworkContext.Provider value={{ offline, online, setOnline, setOffline }}>
      {children}
    </NetworkContext.Provider>
  );
};
