import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  TextField,
  CardHeader,
  ClickAwayListener,
  CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { appDataSelector } from 'app/selectors';
import { Attachment, Edit, MoreVert, Save } from '@material-ui/icons';
import { FilePreview } from 'app/containers/TechnicalVisitPage/ImagesCollection/FilePreview';
import AddIcon from '@material-ui/icons/Add';
import { actions as appActions } from 'app/slice';
import { actions } from '../slice';
import { settingsPageSelector } from '../selectors';
import { Skeleton, SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { ObjectID } from 'bson';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 195,
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
    },
  },
  playIcon: {
    height: 100,
    width: 100,
  },
  customSpeedDial: {},
}));

interface UpdateHandlerParams {
  file?: File;
  title?: string;
}

export const UnifilaireManager = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { lists, isCallingApi } = useSelector(appDataSelector);
  const { unifilaires } = useSelector(settingsPageSelector);
  const [listsOptions, setListsOptions] = React.useState<any>({});
  const [fullscreenFileId, setFullscreenFile] = React.useState<string>();
  const [buttonToInput, setButtonToInput] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState(null);
  const [isAdding, setIsAdding] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const inputFile = React.useRef<HTMLInputElement[]>([]);
  const [listKeys] = React.useState([
    'additional_three-phase',
    'triphasé_dt',
    'single_phase',
    'additional_mono-phase',
  ]);
  const [updateName, setUpdateName] = React.useState<string>('');

  function handleChange(event) {
    setUpdateName(event.target.value);
  }

  const handleUpdate = async (
    id,
    listId,
    name,
    { file, title }: UpdateHandlerParams,
  ) => {
    setIsUpdating(listId);
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = async (e) => {
        const formData = new FormData();
        const data = {
          content: file,
          id,
          path: `unifilaire`,
          extension: 'pdf',
          name: name.split('.')[0],
          type: 'pdf',
          resourceName: undefined,
          resourceId: undefined,
          resourceNameTv: undefined,
          resourceIdTv: undefined,
        };

        await Promise.all(
          Object.keys(data).map((key) => formData.append(key, data[key])),
        );

        dispatch(
          actions.updateUnifilaire({
            data: formData,
          }),
        );
      };
    }

    if (title) {
      const formData = new FormData();
      const data = {
        id,
        path: `unifilaire`,
        extension: 'pdf',
        name: title,
        type: 'pdf',
        resourceName: undefined,
        resourceId: undefined,
        resourceNameTv: undefined,
        resourceIdTv: undefined,
      };

      await Promise.all(
        Object.keys(data).map((key) => formData.append(key, data[key])),
      );
      dispatch(
        appActions.updateListTranslation({
          id: listId,
          name: title,
        }),
      );
      clearInput();

      dispatch(
        actions.updateUnifilaire({
          data: formData,
          list: {
            id: listId,
            key: title.replaceAll(' ', '_'),
          },
        }),
      );
    }
  };
  const handleAdd = async (e, parentId) => {
    setIsAdding(true);
    const { files } = e.target;
    if (files && files.length && files[0]) {
      let file = files[0];

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = async (e) => {
        const generateUuid = new ObjectID().toString();
        const formData = new FormData();
        const data = {
          content: file,
          id: generateUuid,
          path: `unifilaire`,
          extension: 'pdf',
          name: file.name.split('.')[0].replaceAll('_', ' '),
          type: 'pdf',
          resourceName: undefined,
          resourceId: undefined,
          resourceNameTv: undefined,
          resourceIdTv: undefined,
        };

        await Promise.all(
          Object.keys(data).map((key) => formData.append(key, data[key])),
        );

        dispatch(
          actions.addUnifilaire({
            data: formData,
            list: {
              parentId,
              name: file.name.split('.')[0].replaceAll('_', ' '),
              key: file.name.split('.')[0].replaceAll(' ', '_'),
            },
          }),
        );
      };
    }
  };
  React.useEffect(() => {
    dispatch(actions.fetchUnifilaireFiles());
    setListsOptions(
      lists
        .filter((l) => listKeys.includes(l.key))
        .map((l) => ({
          header: l.name,
          id: l.id,
          subLists: l.sublists?.map((cl) => ({
            value: cl.id,
            displayedValue: cl.name,
            isLoading: cl.isLoading || false,
          })),
        })),
    );
    setIsAdding(false);
    setIsUpdating(false);
  }, [setListsOptions, lists, listKeys]);

  const clearInput = () => {
    setUpdateName('');
    setButtonToInput(null);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const addToRefs = (el, key) => {
    if (el && !inputFile.current.includes(el)) {
      inputFile.current[key] = el;
    }
  };

  return (
    <Box>
      {!_.isEmpty(listsOptions) &&
        listsOptions.map((list, i) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{_.startCase(list.header)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                flexWrap="wrap"
                display="flex"
                gridGap="1em"
                justifyContent="flex-start"
              >
                {list.subLists.map((sublist, j) => (
                  <Card variant="outlined" className={classes.root}>
                    <CardHeader
                      style={{ position: 'relative' }}
                      action={
                        <SpeedDial
                          FabProps={{
                            size: 'small',
                            color: 'inherit',
                            style: {
                              background: 'none',
                              boxShadow: 'none',
                            },
                          }}
                          ariaLabel="SpeedDial example"
                          icon={<MoreVert />}
                          onClose={() => handleClose()}
                          onOpen={() => setOpen(sublist.value)}
                          open={open === sublist.value}
                          direction="down"
                          color="secondary"
                          style={{ position: 'absolute', right: 0, top: 0 }}
                        >
                          <SpeedDialAction
                            key="replace"
                            tooltipTitle="Remplacer"
                            tooltipOpen
                            icon={
                              <>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    inputFile?.current[sublist.value]?.click()
                                  }
                                >
                                  <Attachment />
                                  <input
                                    type="file"
                                    ref={(el) => addToRefs(el, sublist.value)}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      const { files } = e.target;
                                      if (files && files.length && files[0]) {
                                        let file = files[0];
                                        handleUpdate(
                                          unifilaires.find(
                                            (uni) =>
                                              decodeURIComponent(uni.name) ===
                                              sublist.displayedValue,
                                          )!.id,
                                          sublist.value,
                                          sublist.displayedValue,
                                          { file },
                                        );
                                      }
                                    }}
                                  />
                                </IconButton>
                              </>
                            }
                          />
                          <SpeedDialAction
                            key="rename"
                            tooltipTitle="Renommer"
                            tooltipOpen
                            icon={
                              <>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    setButtonToInput(sublist.value);
                                    setUpdateName(sublist.displayedValue);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </>
                            }
                          />
                        </SpeedDial>
                      }
                    />
                    <CardContent
                      style={{ height: '140px', textAlign: 'center' }}
                    >
                      {isUpdating === sublist.value ? (
                        <CircularProgress size={100} />
                      ) : unifilaires.find(
                          (uni) =>
                            decodeURIComponent(uni.name) ===
                            sublist.displayedValue,
                        ) ? (
                        <FilePreview
                          file={
                            unifilaires.find(
                              (uni) =>
                                decodeURIComponent(uni.name) ===
                                sublist.displayedValue,
                            )!
                          }
                          onFullscreen={() => setFullscreenFile(sublist.value)}
                          onMinimize={() => setFullscreenFile(undefined)}
                          fullscreen={fullscreenFileId === sublist.value}
                        />
                      ) : (
                        <CircularProgress size={100} />
                      )}
                    </CardContent>
                    <CardActions>
                      <>
                        {buttonToInput !== sublist.value &&
                          (isUpdating === sublist.value ? (
                            <Skeleton width="150px" />
                          ) : (
                            <Button
                              size="small"
                              onClick={() => {
                                setButtonToInput(sublist.value);
                                setUpdateName(sublist.displayedValue);
                              }}
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'left',
                                justifyContent: 'left',
                              }}
                            >
                              {sublist.displayedValue}
                            </Button>
                          ))}
                        {buttonToInput === sublist.value && (
                          <ClickAwayListener onClickAway={clearInput}>
                            <TextField
                              fullWidth
                              defaultValue={sublist.displayedValue}
                              onBlur={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    aria-label="save"
                                    onClick={(e) =>
                                      handleUpdate(
                                        unifilaires.find(
                                          (uni) =>
                                            decodeURIComponent(uni.name) ===
                                            sublist.displayedValue,
                                        )!.id,
                                        sublist.value,
                                        sublist.displayedValue,
                                        { title: updateName },
                                      )
                                    }
                                  >
                                    <Save />
                                  </IconButton>
                                ),
                              }}
                            />
                          </ClickAwayListener>
                        )}
                      </>
                    </CardActions>
                  </Card>
                ))}
                <Card variant="outlined" className={classes.root}>
                  <CardHeader />
                  <CardContent style={{ textAlign: 'center' }}>
                    <IconButton
                      onClick={() => inputFile?.current[list.id]?.click()}
                    >
                      {isCallingApi && isAdding && <CircularProgress />}
                      {!isCallingApi && (
                        <AddIcon className={classes.playIcon} />
                      )}
                      <input
                        type="file"
                        ref={(el) => addToRefs(el, list.id)}
                        style={{ display: 'none' }}
                        onChange={(e) => handleAdd(e, list.id)}
                      />
                    </IconButton>
                  </CardContent>
                  {/* <CardActions>
                    <Typography>Ajouter un document</Typography>
                  </CardActions> */}
                </Card>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};
