import localforage from 'localforage';
import { PayloadAction } from '@reduxjs/toolkit';
import { ObjectID } from 'bson';

import {
  all,
  call,
  cps,
  debounce,
  put,
  select,
  takeLatest,
  fork,
} from 'redux-saga/effects';
import _, { forEach, merge } from 'lodash';
import jwtDecode from 'jwt-decode';
import JSZip from 'jszip';
import { SyncablePathParams } from 'app/types';

import { api } from 'utils/api';
import { actions } from 'app/slice';
import { actions as appActions } from 'app/slice';
import { UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import {
  AddOrUpdateItemParams,
  AddOrUpdateListParams,
  FetchTechnicalVisitsParams,
  Resources,
  SearchTechnicalVisitsParams,
} from 'app/types';
import { dashboardPageSelector } from 'app/containers/DashboardPage/selectors';
import { updateOrAddToCollection } from 'utils/transform';
import { technicalVisit } from 'app/containers/TechnicalVisitPage/initialStates';

import { synchronize } from './syncing';
import { startCaching } from './caching';
import { format } from 'date-fns';
import buildFormData from 'utils/build-formdata';

export { cacheTechnicalVisit } from './caching';

interface SessionToken {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  scope: string;
  sub: string;
}

function* fetchConfig() {
  try {
    const config = yield call(api.config);
    yield put(actions.setConfig(config));
  } catch (e) {
    console.error(e);
  }
}

function* initializeApp(action: PayloadAction<string>) {
  try {
    const sessionToken = action.payload;
    const { sub: id } = jwtDecode<SessionToken>(sessionToken);

    yield all([fetchCurrentUser(id), fetchEntireLists()]);

    yield fork(function* () {
      yield put(actions.startCaching());
    });

    yield put(actions.showApp());
  } catch (e) {
    console.error(e);
  }
}

function* fetchCurrentUser(id) {
  const currentUser: UserInterface = yield call(api.users.one, id);
  yield put(actions.setCurrentUser(currentUser));
}

function* fetchLists(action: PayloadAction<Array<string>>) {
  try {
    const keys = action.payload;
    const { lists: currentLists } = yield select(appDataSelector);
    const key = keys.filter((k) => !currentLists.find((cl) => cl.key === k));
    if (key.length > 0) {
      const lists = yield call(api.lists.search, { key });
      yield put(actions.setLists([...currentLists, ...lists]));
    }
  } catch (e) {
    console.log(e);
  }
}
function* fetchEntireLists() {
  try {
    const lists = yield call(api.lists.all);
    if (lists.length > 0) yield put(actions.setLists(lists));
  } catch (e) {
    console.log(e);
  }
}
function* updateList({ payload }: PayloadAction<AddOrUpdateListParams>) {
  try {
    const { lists: currentLists } = yield select(appDataSelector);
    const newList = yield call(api.lists.add, { ...payload });
    yield put(
      actions.setLists(
        currentLists.map((cl) =>
          cl.id === newList.parentId
            ? {
                ...cl,
                sublists: [{ ...newList, name: payload.name }, ...cl.sublists],
              }
            : cl,
        ),
      ),
    );
  } catch (e) {
    console.log(e);
  }
}
function* updateListTranslation({
  payload,
}: PayloadAction<{ id: number; name: string }>) {
  yield call(api.lists.updateTranslation, { ...payload });

  const lists = yield call(api.lists.all);
  if (lists.length > 0) yield put(actions.setLists(lists));
}

function* addOrUpdateItem<T>(action: PayloadAction<AddOrUpdateItemParams<T>>) {
  try {
    let {
      resource,
      item,
      key = 'id',
      refreshResource = false,
      isDuplicate = false,
    } = action.payload;
    const apiAction = isDuplicate
      ? 'duplicate'
      : _.isNumber((item as any).id) || !_.isEmpty((item as any).id)
      ? 'update'
      : 'add';
    const updatedItem = yield call(api[resource][apiAction], item);
    const { [resource]: items, technicalVisit } = yield select(appDataSelector);

    if (refreshResource) {
      const addOrUpdate =
        apiAction === 'add'
          ? [{ technicalVisit: updatedItem }, ...technicalVisit.items]
          : technicalVisit.items.map((vt) =>
              vt.technicalVisit.id === updatedItem.id
                ? {
                    ...vt,
                    technicalVisit: {
                      ...updatedItem,
                      technicianName: vt.technicalVisit.technicianName,
                    },
                  }
                : vt,
            );
      yield put(
        appActions.setTechnicalVisits({
          ...technicalVisit,
          items: addOrUpdate,
          totalPages: 0,
        }),
      );
      const { customer, ...tv } = updatedItem;
      const { resourceDialogParams } = yield select(appDataSelector);
      yield put(
        actions.setResourceDialogParams({
          ...resourceDialogParams,
          items: {
            [Resources.CUSTOMER]: customer,
            [Resources.TECHNICAL_VISIT]: tv,
          },
          isDuplicate: false,
        }),
      );
      //  yield put(actions.setItems({ resource: resource, items: [] }));
    } else {
      yield put(
        actions.setItemResourceDialogParams({
          resource,
          item: updatedItem,
          foreignKey: { [`${resource}Id`]: updatedItem.id },
          isDuplicate: false,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* searchTechnicalVisitsPage({
  body: params,
  fetchNextPage,
  query,
}: SearchTechnicalVisitsParams) {
  const { technicalVisit: oldTechnicalVisits } = yield select(appDataSelector);
  const technicalVisits = yield call(
    api.technicalVisit.searchTechnicalVisit,
    params,
    query,
  );

  if (!query?.export) {
    const page = Number(technicalVisits?.meta?.currentPage || 1);
    const nextPage = fetchNextPage ? page + 1 : null;
    const items =
      page > 1
        ? [...oldTechnicalVisits.items]
        : new Array(Number(technicalVisits.meta.total));
    items.splice(
      (page - 1) * 100,
      technicalVisits.data.length,
      ...technicalVisits.data,
    );

    yield put(
      actions.setTechnicalVisits({
        totalPages: Number(technicalVisits.meta.lastPage),
        total: Number(technicalVisits.meta.total),
        lastFetchedPage: page,
        items,
        fetchingOrFetchedPages: _.compact([
          ...(page > 1 ? oldTechnicalVisits.fetchingOrFetchedPages : []),
          nextPage,
          page,
        ]),
      }),
    );
  }
}

function* searchTechnicalVisits(
  action: PayloadAction<SearchTechnicalVisitsParams>,
) {
  try {
    const { fetchNextPage, body } = action.payload;
    yield searchTechnicalVisitsPage(action.payload);
    if (fetchNextPage)
      yield searchTechnicalVisitsPage({
        fetchNextPage: false,
        body: {
          ...body,
          page: body.page + 1,
        },
      });
  } catch (e) {
    console.log(e);
  }
}

function* exportCachedData({ payload }) {
  const {
    technicalVisitsCached,
    technicalVisitsSyncablePaths,
    syncStatus,
    currentUser: { email },
  } = yield select(appDataSelector);
  var jsons = JSON.stringify({
    technicalVisitsCached,
    technicalVisitsSyncablePaths,
    syncStatus,
  });
  try {
    var jsonBlob = new Blob([jsons], { type: 'application/json' });

    const zip = new JSZip();
    zip.file('store.json', jsonBlob);
    const files = _.flatten(
      Object.values(technicalVisitsSyncablePaths).map((technicalVisit) =>
        Object.values(technicalVisit as SyncablePathParams).map(
          (syncablePath) => syncablePath,
        ),
      ),
    );
    for (let i = 0; i < files.length; i++) {
      const { data } = files[i];
      if (data.extension) {
        zip!.file(
          `${data.path}/${data.name.replaceAll('/', '-')}.${data.extension}`,
          yield localforage.getItem(data.id),
        );
      }
    }
    zip.generateAsync({ type: 'blob' }).then(async (content) => {
      if (!payload?.download) {
        const generateUuid = new ObjectID().toString();
        const formData = new FormData();
        const data = {
          content,
          id: generateUuid,
          path: `app_states/${email}`,
          extension: 'zip',
          name: `${format(new Date(), 'dd-MM-yy_HH-mm')}`,
          type: 'zip',
          resourceName: undefined,
          resourceId: undefined,
          resourceNameTv: undefined,
          resourceIdTv: undefined,
        };
        await Promise.all(
          Object.keys(data).map((key) => formData.append(key, data[key])),
        );
        await api.files.add(formData);
      } else {
        var url = URL.createObjectURL(content);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute(
          'download',
          `${email}_${format(new Date(), 'dd-MM-yy_HH-mm')}.zip`,
        );
        tempLink.click();
        document.removeChild(tempLink);
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export function* appDataSaga() {
  yield debounce(1000, actions.initializeApp.type, initializeApp);
  // yield takeLatest(actions.initializeApp.type, initializeApp);
  yield takeLatest(actions.fetchLists, fetchLists);
  yield takeLatest(actions.exportedCachedData, exportCachedData);
  yield takeLatest(actions.fetchEntireLists, fetchEntireLists);
  yield takeLatest(actions.updateListTranslation, updateListTranslation);
  yield takeLatest(actions.addOrUpdateItem, addOrUpdateItem);
  yield takeLatest(actions.updateList, updateList);
  yield debounce(1000, actions.searchTechnicalVisits, searchTechnicalVisits);
  // yield takeLatest(actions.searchTechnicalVisits, searchTechnicalVisits);
  yield debounce(100, actions.startCaching, startCaching);
  yield debounce(2500, actions.startSyncing, synchronize);
  yield takeLatest(actions.fetchConfig, fetchConfig);
}
