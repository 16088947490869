import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, CountryCodeInterface } from './types';
import { UserInterface, userInitialState } from 'types';

// The initial state of the UserDialog container
export const initialState: ContainerState = {
  user: userInitialState,
  countriesCodes: [],
};

const userDialogSlice = createSlice({
  name: 'userDialog',
  initialState,
  reducers: {
    fetchCountriesCodes(state) {},
    addOrUpdateUser(state, action: PayloadAction<UserInterface>) {},
    setCountriesCodes(state, action: PayloadAction<CountryCodeInterface[]>) {
      state.countriesCodes = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userDialogSlice;
