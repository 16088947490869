import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, TextField } from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { EquipmentInterface } from '../types';
import { useFormikContext } from 'formik';
import { actions } from 'app/slice';

export const Form = () => {
  const { values } = useFormikContext<EquipmentInterface>();
  const dispatch = useDispatch();
  const { setValidationSchema, variant } = useStepperContext();
  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );
  const [listKeys] = React.useState(['equipment_type']);

  React.useEffect(() => {
    dispatch(actions.fetchLists(listKeys));
  }, [dispatch, listKeys]);

  React.useEffect(() => {
    const options = {};
    // eslint-disable-next-line array-callback-return
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);
  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          name: yup.string().nullable(),
          type: yup.number().nullable(),
          isOutlet: yup.boolean().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="name"
              label="nom"
              extraAttr={{ ...variant, disabled: isSupplier }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
          </Box>
          {!values.isOutlet && (
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="type"
                label={'système de pilotage'}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['equipment_type'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
