import { createGlobalStyle } from 'styled-components';
import { withTheme } from '@material-ui/core';

export const GlobalStyle = withTheme(createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    overscroll-behavior-y: contain;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: ${(props) =>
      (props.theme as any).palette.background.default};
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  //Google Maps SearchBox Select
  .pac-container {
    z-index: 1300 !important
  }
`);
