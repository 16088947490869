import { Image } from 'app/containers/TechnicalVisitPage/types';
import { isFunction, isString } from 'lodash';
import localforage from 'localforage';

localforage.config({ name: 'files' });

const staticMapping = [
  'resourceName',
  'resourceId',
  'resourceNameTv',
  'resourceIdTv',
  'title',
  'path',
  'name',
  'id',
];

const dynamicMapping = {
  content: async ({ id }) => await localforage.getItem(id),
};

const buildFormData = async (data) => {
  const formData = new FormData();

  staticMapping.forEach((key) => formData.append(key, data[key]));
  await Promise.all(
    Object.keys(dynamicMapping).map(async (key) => {
      const value = await dynamicMapping[key](data);
      if (value) formData.append(key, value);
    }),
  );

  return formData;
};

export default buildFormData;
