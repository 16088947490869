import React from 'react';
import localforage from 'localforage';
import styled from 'styled-components';
import { API_URL } from 'utils/api';

import { Fab, Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import fallbackImage from 'app/assets/documents.jpg';
import { Image } from 'app/containers/TechnicalVisitPage/types';
import apiUrlBuilder from 'utils/api-url-builder';

localforage.config({ name: 'files' });

interface Props {
  file: Image;
  fullscreen?: boolean;
  onFullscreen?: () => void;
  onMinimize?: () => void;
}

const ImageDialog = styled(Dialog)`
  .MuiPaper-root {
    height: calc(100% - 64px);

    img {
      max-height: 100%;
    }

    .MuiFab-root {
      position: absolute;
      top: 0%;
      right: 0%;
      margin: 5px;
      color: white;
      background-color: #c62828;
    }
  }
`;

export const FilePreview = ({
  file,
  fullscreen = false,
  onFullscreen,
  onMinimize,
}: Props) => {
  const [dbFileUrl, setDbFileUrl] = React.useState<string>();

  // const getFromDb = async (fileParams) => {
  //   setDbFileUrl(undefined);
  //   const file = await localforage.getItem<File>(fileParams.id);
  //   if (file) {
  //     setDbFileUrl(window.URL.createObjectURL(file));
  //   }
  // };

  React.useEffect(() => {
    setDbFileUrlAsync();

    async function setDbFileUrlAsync() {
      setDbFileUrl(undefined);
      const dbFile = await localforage.getItem<File>(file.id);
      if (dbFile) {
        setDbFileUrl(window.URL.createObjectURL(dbFile));
      }
    }
  }, [file]);

  const signedUrl = React.useMemo<string | undefined>(() => {
    if (!file.signedUrl || !API_URL) return;
    return apiUrlBuilder(API_URL, file.signedUrl);
  }, [file]);

  const previewUrl = React.useMemo<string | undefined>(() => {
    if (file.extension !== 'pdf' && (dbFileUrl || signedUrl)) {
      return dbFileUrl || signedUrl;
    }
    return fallbackImage;
  }, [dbFileUrl, file, signedUrl]);

  const fileUrl = React.useMemo<string | undefined>(
    () => dbFileUrl || signedUrl,
    [dbFileUrl, signedUrl],
  );

  return (
    <>
      <ImageDialog maxWidth="lg" open={fullscreen} onClose={onMinimize}>
        {file.extension !== 'pdf' && (
          <Fab onClick={onMinimize} children={<Close />} size="small" />
        )}
        {file.extension === 'pdf' ? (
          <iframe
            title="pdf-viewer"
            src={fileUrl}
            style={{ height: '100%', width: '700px', maxWidth: '100%' }}
            frameBorder="0"
          ></iframe>
        ) : (
          <img src={fileUrl} alt="fullscreen-icon" />
        )}
      </ImageDialog>
      <img
        src={previewUrl}
        alt={file.name}
        style={{ maxHeight: '100%' }}
        onClick={onFullscreen}
      />
    </>
  );
};
