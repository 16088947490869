import { appDataSaga } from './appData';
import { userDialogSaga } from 'app/containers/UserDialog/saga';
import { settingsPageSaga } from 'app/containers/SettingsPage/saga';
import { dashboardPageSaga } from 'app/containers/DashboardPage/saga';
import { technicalVisitPageSaga } from 'app/containers/TechnicalVisitPage/saga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    appDataSaga(),
    userDialogSaga(),
    settingsPageSaga(),
    dashboardPageSaga(),
    technicalVisitPageSaga(),
  ]);
}
