/**
 *
 * SimpleSelect
 *
 */
import React from 'react';

import {
  TextField,
  TextFieldProps,
  ListSubheader,
  MenuItem,
} from '@material-ui/core';

export interface MenuOption {
  value: React.ReactText;
  displayedValue?: React.ReactText;
  disabled?: boolean;
  subLists: MenuOption[];
}

export interface GroupMenuOption {
  header: React.ReactText;
  subLists: MenuOption[];
}

type Props = TextFieldProps & {
  options: GroupMenuOption[];
  subOptions: any;
};

export const GroupSelect = ({ subOptions, options, ...props }: Props) => (
  <TextField
    select
    value={props.value || null}
    {...props}
    InputLabelProps={{
      ...props.InputLabelProps,
      shrink: true,
    }}
    SelectProps={{
      ...props.SelectProps,
      fullWidth: true,
    }}
  >
    {options.map(({ header, subLists }) => [
      <ListSubheader disableSticky key={header}>
        {header}
      </ListSubheader>,
      subLists.map(({ value, displayedValue }) => (
        <MenuItem key={value} value={value}>
          {displayedValue || value}
        </MenuItem>
      )),
    ])}
  </TextField>
);
