import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import _ from 'lodash';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption } from 'app/components/SimpleSelect';
import { useFormikContext } from 'formik';
import { TechnicalVisitInterface } from 'app/types';

export const Form = () => {
  const { t } = useTranslation();

  const { values } = useFormikContext<TechnicalVisitInterface>();
  const { setValidationSchema, variant } = useStepperContext();
  // const { users } = useSelector(dashboardPageSelector);

  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  // const [usersOptions, setUsersOptions] = React.useState([] as MenuOption[]);

  // const [listsOptions, setListsOptions] = React.useState(
  //   {} as { [key: string]: MenuOption[] },
  // );
  const [listKeys] = React.useState(['type_of_selling']);

  React.useEffect(() => {
    const options = {};
    // eslint-disable-next-line array-callback-return
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    // setListsOptions(options);
  }, [lists, listKeys]);

  // React.useEffect(() => {
  //   if (isAdmin) {
  //     const options = users
  //       .filter(
  //         (user) =>
  //           user.role === Roles.BASIC && user.status === Statuses.ENABLED,
  //       )
  //       .map((user) => ({
  //         value: user.id,
  //         displayedValue: user.name,
  //       })) as MenuOption[];
  //     setUsersOptions(options);
  //   } else {
  //     setUsersOptions([
  //       { value: currentUser!.id!, displayedValue: currentUser!.name },
  //     ]);
  //   }
  // }, [setUsersOptions, users, currentUser, isAdmin]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          cadastralPlot: yup.string().nullable(),
          internetProvider: yup.string().nullable(),
          houseArea: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          difficultAccess: yup.boolean().default(false),
          needCarrycotLadder: yup.boolean().default(false),
          needTrench: yup.boolean().default(false),
          replacementAc: yup.boolean().default(false),
          roofAccessSpace: yup.boolean().default(false),
          difficultAccessComment: yup.string().nullable(),
          needCarrycotLadderComment: yup.string().nullable(),
          needTrenchComment: yup.string().nullable(),
          replacementAcComment: yup.string().nullable(),
          roofAccessSpaceComment: yup.string().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection={onSmallDevice ? 'column' : 'row'}
      ></Box>

      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <FormikInput
          name="houseArea"
          label={t(translations.TechnicalVisitForms.labels.houseArea)}
          extraAttr={{
            ...variant,
            disabled: isSupplier,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
        <FormikInput
          name="cadastralPlot"
          label={t(translations.TechnicalVisitForms.labels.cadastralPlot)}
          extraAttr={{
            ...variant,
            disabled: isSupplier,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
        <FormikInput
          name="internetProvider"
          label={t(translations.TechnicalVisitForms.labels.internetProvider)}
          extraAttr={{
            ...variant,
            disabled: isSupplier,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={onSmallDevice ? 'column' : 'row'}
      >
        <Box>
          <FormikInput
            name="difficultAccess"
            label={t(translations.TechnicalVisitForms.labels.difficultAccess)}
            extraAttr={{
              disabled: isSupplier,
              labelPlacement: 'bottom',
              checked: values?.['difficultAccess'] || false,
              control: <Switch size="small" />,
            }}
            containerStyle={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
            component={FormControlLabel}
          />
          {values?.['difficultAccess'] && (
            <FormikInput
              name="difficultAccessComment"
              label={t(translations.GeneratorForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                rows: 6,
                disabled: isSupplier,
                multiline: true,
              }}
              containerStyle={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={TextField}
            />
          )}
        </Box>
        <Box>
          <FormikInput
            name="needCarrycotLadder"
            label={t(
              translations.TechnicalVisitForms.labels.needCarrycotLadder,
            )}
            extraAttr={{
              disabled: isSupplier,
              labelPlacement: 'bottom',
              checked: values?.['needCarrycotLadder'] || false,
              control: <Switch size="small" />,
            }}
            containerStyle={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
            component={FormControlLabel}
          />
          {values?.['needCarrycotLadder'] && (
            <FormikInput
              name="needCarrycotLadderComment"
              label={'Hauteur nacelle...'}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 6,
                multiline: true,
              }}
              containerStyle={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={TextField}
            />
          )}
        </Box>
        <Box>
          <FormikInput
            name="needTrench"
            label={t(translations.TechnicalVisitForms.labels.needTrench)}
            extraAttr={{
              disabled: isSupplier,
              labelPlacement: 'bottom',
              checked: values?.['needTrench'] || false,
              control: <Switch size="small" />,
            }}
            containerStyle={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
            component={FormControlLabel}
          />
          {values?.['needTrench'] && (
            <FormikInput
              name="needTrenchComment"
              label={'Longueur tranchée'}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 6,
                multiline: true,
              }}
              containerStyle={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={TextField}
            />
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={onSmallDevice ? 'column' : 'row'}
      >
        <Box>
          <FormikInput
            name="replacementAc"
            label={t(translations.TechnicalVisitForms.labels.replacementAc)}
            extraAttr={{
              disabled: isSupplier,
              labelPlacement: 'bottom',
              checked: values?.['replacementAc'] || false,
              control: <Switch size="small" />,
            }}
            containerStyle={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
            component={FormControlLabel}
          />
          {values?.['replacementAc'] && (
            <FormikInput
              name="replacementAcComment"
              label={t(translations.GeneratorForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 6,
                multiline: true,
              }}
              containerStyle={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={TextField}
            />
          )}
        </Box>
        <Box>
          <FormikInput
            name="roofAccessSpace"
            label={t(translations.TechnicalVisitForms.labels.roofAccessSpace)}
            extraAttr={{
              disabled: isSupplier,
              labelPlacement: 'bottom',
              checked: values?.['roofAccessSpace'] || false,
              control: <Switch size="small" />,
            }}
            containerStyle={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
            component={FormControlLabel}
          />
          {values?.['roofAccessSpace'] && (
            <FormikInput
              name="roofAccessSpacecComment"
              label={t(translations.GeneratorForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 6,
                multiline: true,
              }}
              containerStyle={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={TextField}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
