/**
 *
 * VirtualizedTable
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { TableProps, ColumnVariant, ColumnData } from './types';

import { Box } from '@material-ui/core';
import { AutoSizer, Column, Table, SortDirection } from 'react-virtualized';
import { ActionsBar } from './ActionsBar';
import { ColumnCell } from './ColumnCell';

export * from './types';
export { SortDirection };

export const VirtualizedTable = function ({
  columns,
  headerHeight = 48,
  rowHeight = 48,
  actionsParams,
  initialSortParams,
  hasHorizontalScroll = false,
  onRowsRendered,
  sort,
  scrollTop,
  ...props
}: TableProps) {
  const [sortParams, setSortParams] = React.useState(
    initialSortParams || {
      sortBy: columns[0].dataKey,
      sortDirection: SortDirection.ASC,
    },
  );

  const calculatedCellWidth = (width: number, ratio: number): number => {
    const takenWidth = _.chain(columns)
      .map((column: ColumnData) => column.width || 0)
      .sum()
      .value();

    return (width - takenWidth) * ratio;
  };

  if (actionsParams && actionsParams.icons.length > 0) {
    const actionColumnIndex = _.findIndex(columns, ['dataKey', 'actions']);
    if (actionColumnIndex !== -1) columns.splice(actionColumnIndex);
    const actionsColumnWidth =
      30 * ((actionsParams.maxVisibleIcons || actionsParams.icons.length) + 1);
    columns.push({
      dataKey: 'actions',
      width: actionsColumnWidth,
      cellRenderer: ({ rowData, rowIndex: index }) =>
        ActionsBar({
          actions: actionsParams.icons,
          rowData,
          index,
        }),
      extraStyle: {
        justifyContent: 'flex-end',
      },
    });
  }

  React.useEffect(() => {
    sort && sort(sortParams);
  }, [sort, sortParams]);

  const sortable = (sort) => {
    const sortDirection =
      sort.sortBy !== sortParams.sortBy
        ? initialSortParams?.sortDirection || sortParams.sortDirection
        : sortParams.sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC;

    setSortParams({
      sortBy: sort.sortBy,
      sortDirection,
    });
  };
  return (
    <Box style={{ flex: '1 1 auto' }}>
      <AutoSizer>
        {({ height, width: tableWidth }) => (
          <FlexTable
            title="table"
            scrollTop={scrollTop ? 1 : null}
            height={height}
            width={
              hasHorizontalScroll
                ? _.chain(columns)
                    .map((column: ColumnData) => column.width || 0)
                    .sum()
                    .value()
                : tableWidth
            }
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            onRowsRendered={onRowsRendered}
            sortDirection={sortParams.sortDirection}
            {...props}
          >
            {columns.map(
              ({
                dataKey,
                width: cellWidth,
                widthRatio: cellWidthRatio = 0,
                label = '',
                isSortable = false,
                cellRenderer = ({ cellData }) => cellData as string,
                extraStyle = {},
                isVisible = true,
              }) =>
                isVisible && (
                  <Column
                    flexShrink={hasHorizontalScroll ? 0 : 1}
                    cellDataGetter={({ rowData }) => _.get(rowData, dataKey)}
                    key={dataKey}
                    dataKey={dataKey}
                    maxWidth={
                      cellWidth ||
                      calculatedCellWidth(tableWidth, cellWidthRatio)
                    }
                    width={
                      cellWidth ||
                      calculatedCellWidth(tableWidth, cellWidthRatio)
                    }
                    label={label}
                    headerRenderer={({ label }) => (
                      <>
                        <ColumnCell
                          children={label}
                          variant={ColumnVariant.HEAD}
                          style={{
                            height: headerHeight,
                            ...extraStyle,
                          }}
                          {...{
                            dataKey,
                            isSortable,
                            sortParams,
                            sortable,
                          }}
                        />
                      </>
                    )}
                    cellRenderer={(datas) =>
                      ColumnCell({
                        children: cellRenderer(datas),
                        dataKey,
                        variant: ColumnVariant.BODY,
                        style: {
                          height: rowHeight,
                          ...extraStyle,
                        },
                      })
                    }
                  />
                ),
            )}
          </FlexTable>
        )}
      </AutoSizer>
    </Box>
  );
};

const FlexTable = styled(Table)`
  .ReactVirtualized__Table__headerRow,
  .ReactVirtualized__Table__row,
  .ReactVirtualized__Table {
    display: flex;
    flex: 1;
  }
`;
