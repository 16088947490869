import { States } from 'types';
import { UserInterface } from 'types/users';

/* --- STATE --- */
export enum Resources {
  TECHNICAL_VISIT = 'technicalVisit',
  CUSTOMER = 'customer',
}

interface FetchFiltersResourcesParams {
  name: string;
  query?: { [key: string]: string | number };
  unsetOrganizationId?: boolean;
}

export interface FetchFiltersParams {
  resources: FetchFiltersResourcesParams[];
}

export interface DeleteItemParams {
  resource: Resources;
  id: string;
}

export interface DashboardPageState {
  users: UserInterface[];
  history: { [key: string]: HistoryInterface[] };
  filters: { [key: string]: any };
}

export interface SetItemParams {
  resource: string;
  item: any;
  index?: number;
}

export interface SetHistoryParams {
  history: HistoryInterface[];
  collection: string;
}
export interface HistoryInterface {
  userId: string;
  userFullname: string;
  action: string;
  tags: { [key: string]: string };
  resource: HistoryResource;
  attributes: {
    [attribute: string]: {
      name?: string;
      current: string;
      old: string;
      type?: 'string' | 'number' | 'file';
      action?: 'create' | 'update' | 'delete';
    };
  };
  createdAt: Date;
}

interface HistoryResource {
  name: string;
  id: string;
  description: string;
}

export type ContainerState = DashboardPageState;
