import { ResourceDialogParams, Resources } from 'app/types';
import React from 'react';
import * as yup from 'yup';

import { CustomerForm, TechnicalVisitForm } from './Forms';

interface Props {
  children?: JSX.Element;
  items: ResourceDialogParams['items'];
  readOnly: boolean;
}

interface ContextInterface {
  validationSchema: yup.ObjectSchema<any, any, any, any>;
  setValidationSchema: (schema: yup.ObjectSchema<any, any, any, any>) => void;
  resourceForms: {
    [key in Extract<
      Resources,
      Resources.TECHNICAL_VISIT | Resources.CUSTOMER
    >]: JSX.Element;
  };
  variant: { variant: string };
  items: ResourceDialogParams['items'];
  readOnly: boolean;
}

const Context = React.createContext<ContextInterface>({} as ContextInterface);
export const useDialogContext = () => React.useContext(Context);

export const DialogContext = {
  Consumer: Context.Consumer,
  Provider: ({ children, items, readOnly }: Props) => {
    const [validationSchema, setValidationSchema] = React.useState(
      {} as yup.ObjectSchema<any, any, any, any>,
    );

    return (
      <Context.Provider
        value={{
          validationSchema,
          setValidationSchema,
          resourceForms: {
            [Resources.TECHNICAL_VISIT]: <TechnicalVisitForm />,
            [Resources.CUSTOMER]: <CustomerForm />,
          },
          variant: { variant: 'outlined' },
          items,
          readOnly,
        }}
      >
        {children}
      </Context.Provider>
    );
  },
};
