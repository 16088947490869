/* eslint-disable no-use-before-define */
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { capitalize } from 'lodash';

import { translations } from 'locales/i18n';

export interface MenuOption {
  value: React.ReactText;
  displayedValue?: React.ReactText;
  disabled?: boolean;
}

type Props = TextFieldProps & {
  options: MenuOption[];
  ignoreDisabling?: boolean;
  value: any;
  canDisplayAdd?: boolean;
  addItem: (item) => void;
};

const filter = createFilterOptions<MenuOption>();

export const AutoCompleteSelect = ({
  options,
  ignoreDisabling = false,
  value,
  canDisplayAdd = false,
  addItem,
  onChange,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState<string>(value);

  React.useEffect(() => {
    setInputValue(
      capitalize(
        String(options.find((o) => o.value === value)?.displayedValue || ''),
      ) || '',
    );
  }, [options, value]);

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      disableClearable
      fullWidth
      onInputChange={(_, v) => setInputValue(v)}
      selectOnFocus
      noOptionsText={t(translations.component.autocomplete.noOption)}
      clearOnBlur={false}
      options={options}
      getOptionLabel={(option) =>
        option.displayedValue?.replace(
          `${t(translations.component.autocomplete.create)} `,
          '',
        )
      }
      renderOption={(option) => capitalize(option.displayedValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          error={props.error}
          helperText={props.helperText}
        />
      )}
      onChange={(_, newValue) => {
        if (!options.find(({ value }) => newValue.value === value)) {
          addItem(newValue.value);
        }
        onChange && onChange(newValue.value);
        return newValue.value;
      }}
      getOptionSelected={(option) => option.value === value}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (
          canDisplayAdd &&
          params.inputValue !== '' &&
          !options.find(
            ({ value }) =>
              value === params.inputValue.replace(' ', '_').toLowerCase(),
          )
        ) {
          filtered.push({
            value: params.inputValue,
            displayedValue: `${t(translations.component.autocomplete.create)} ${
              params.inputValue
            }`,
          });
        }

        return filtered;
      }}
    />
  );
};
