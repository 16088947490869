import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { lightTheme } from 'utils/theme';
import { appDataSelector } from 'app/selectors';
import { actions } from 'app/slice';
import { CircularProgress } from 'app/components/CircularProgress';
import { ThemeProvider } from '@material-ui/core';

interface Props {
  children?: any;
}

export const ConfigProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { config } = useSelector(appDataSelector);

  const clearAppCaches = async () => {
    const cachesKeys = await caches.keys();

    await Promise.all(
      cachesKeys.map(async (cacheKey) => {
        if (cacheKey.includes('workbox')) await caches.delete(cacheKey);
      }),
    );

    window.location.reload();
  };

  React.useEffect(() => {
    console.log(process.env.REACT_APP_VERSION, config?.version);
    if (config && config.version !== process.env.REACT_APP_VERSION) {
      clearAppCaches();
    }
  }, [config]);

  React.useEffect(() => {
    dispatch(actions.fetchConfig());
  }, []);

  return (
    <>
      {config && process.env.REACT_APP_VERSION === config.version ? (
        children
      ) : (
        <ThemeProvider theme={lightTheme} children={<CircularProgress />} />
      )}
    </>
  );
};
