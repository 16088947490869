import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';
import _ from 'lodash';
import { api } from 'utils/api';
import { actions } from './slice';
import { actions as appActions } from 'app/slice';
import { dashboardPageSelector } from './selectors';
import { FetchFiltersParams, DeleteItemParams } from './types';
import { appDataSelector } from 'app/selectors';

function* fetchFilters(action: PayloadAction<FetchFiltersParams>) {
  try {
    const { resources } = action.payload;
    yield all(
      resources.map(function* ({ name, query }) {
        let { [name]: items } = yield select(dashboardPageSelector);
        items = yield call(api[name].all, query);
        yield put(actions.setItemsFilter({ resource: name, items }));
      }),
    );
  } catch (e) {}
}

function* deleteItem<T>(action: PayloadAction<DeleteItemParams>) {
  try {
    let { resource, id } = action.payload;
    yield call(api[resource]['delete'], id);

    const { technicalVisit } = yield select(appDataSelector);
    let allItems = [...technicalVisit.items];
    yield put(
      appActions.setTechnicalVisits({
        ...technicalVisit,
        total: technicalVisit.total - 1,
        items: allItems.filter((i) => i.id !== id),
        totalPages: 0,
      }),
    );
  } catch (e) {
    console.log(e);
  }
}
function* fetchHistory({
  payload: { mainId, ...payload },
}: PayloadAction<any>) {
  const history = yield call(api.history.all, payload);
  const { history: collections } = yield select(dashboardPageSelector);
  if (
    history &&
    history.length > 0 &&
    JSON.stringify(history) !== JSON.stringify(collections[mainId])
  ) {
    yield put(
      actions.setHistory({
        history,
        collection: mainId,
      }),
    );
  }
}

export function* dashboardPageSaga() {
  yield takeLatest(actions.fetchFilters, fetchFilters);
  yield takeLatest(actions.deleteItem, deleteItem);
  yield takeEvery(actions.fetchHistory, fetchHistory);
}
