/**
 *
 * CircularProgress
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core';

export const CircularProgress = () => (
  <StyledBox
    display="flex"
    alignSelf="center"
    alignItems="center"
    justifyContent="center"
  >
    <MuiCircularProgress />
  </StyledBox>
);

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
`;
