/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import { reducer as appDataReducer, sliceKey as appDataKey } from 'app/slice';
import {
  reducer as userDialogReducer,
  sliceKey as userDialogKey,
} from 'app/containers/UserDialog/slice';
import {
  reducer as settingsPageReducer,
  sliceKey as settingsPageKey,
} from 'app/containers/SettingsPage/slice';
import {
  reducer as DashboardPageReducer,
  sliceKey as DashboardPageKey,
} from 'app/containers/DashboardPage/slice';
import {
  reducer as TechnicalVisitPageReducer,
  sliceKey as TechnicalVisitPageKey,
} from 'app/containers/TechnicalVisitPage/slice';
import { States } from 'types';

const commonPersistConfig = {
  version: 1,
  timeout: 0,
  throttle: 50,
};

const appDataTransform = createTransform(
  (state: any, key, { currentUser, technicalVisitsSyncablePaths }) => {
    const technicalVisitsCached = JSON.parse(JSON.stringify(state));
    Object.keys(technicalVisitsCached).forEach((id) => {
      if (!currentUser) return technicalVisitsCached;

      const {
        technicalVisit: { technician, status },
      } = technicalVisitsCached[id];

      if (
        !technicalVisitsSyncablePaths[id] &&
        (technician !== currentUser.id || status !== States.PLANIFIED)
      )
        delete technicalVisitsCached[id];
    });
    return technicalVisitsCached;
  },
  (state) => state,
  {
    whitelist: ['technicalVisitsCached'],
  },
);

export default combineReducers({
  // [appDataKey]: appDataReducer,
  // [DashboardPageKey]: DashboardPageReducer,
  [settingsPageKey]: settingsPageReducer,
  [TechnicalVisitPageKey]: TechnicalVisitPageReducer,
  [userDialogKey]: userDialogReducer,
  [appDataKey]: persistReducer(
    {
      ...commonPersistConfig,
      key: appDataKey,
      storage: storage(appDataKey),
      transforms: [appDataTransform],
      blacklist: [
        'appIsReady',
        'currentUser',
        'syncStatus',
        'config',
        'userDialogParams',
        'apiCalls',
        'isCallingApi',
      ],
    },
    appDataReducer,
  ),
  [DashboardPageKey]: persistReducer(
    {
      ...commonPersistConfig,
      key: DashboardPageKey,
      storage: storage(DashboardPageKey),
    },
    DashboardPageReducer,
  ),
});
