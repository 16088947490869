import React from 'react';
import styled from 'styled-components/macro';
import { startOfDay, endOfDay } from 'date-fns';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { Box } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { CustomDateField } from './CustomDateField';
import { CloseRounded } from '@material-ui/icons';

interface PickerProps {
  key: string;
  label: string;
  value: ParsableDate;
  onChange: (date?: Date) => void;
}

interface Props {
  fromPickerProps: PickerProps;
  untilPickerProps: PickerProps;
  resetIcon?: typeof CloseRounded;
  label?: string;
}

export const DateTimeRangePicker = ({
  fromPickerProps,
  untilPickerProps,
  resetIcon = () => <CloseRounded color="error" />,
  label = '',
}: Props) => {
  const [date, setDate] = React.useState({
    from: fromPickerProps.value,
    until: untilPickerProps.value,
  });
  const today = new Date();

  return (
    <RelativeBox display="flex">
      <Label className={date.from || date.until ? 'on-filled' : ''}>
        {label}
      </Label>

      {[fromPickerProps, untilPickerProps].map(
        ({ key, label, value = null, onChange }, idx) => (
          <DateTimePicker
            initialFocusedDate={idx === 0 ? startOfDay(today) : endOfDay(today)}
            label={label}
            variant="inline"
            ampm={false}
            emptyLabel="∞"
            value={value}
            onChange={(date) => setDate((d) => ({ ...d, [key]: date }))}
            onClose={() => {
              onChange(date[key]);
              setDate((d) => ({ ...d, [key]: date }));
            }}
            format="dd/MM/yy HH:mm"
            TextFieldComponent={(props) => (
              <CustomDateField
                alignment={idx === 0 ? 'start' : 'end'}
                {...props}
                showAdornment={!!value}
                onResetField={() => onChange && onChange(undefined)}
                resetIcon={resetIcon}
              />
            )}
            {...(!!date.from && idx === 1
              ? {
                  minDate: date.from,
                }
              : {})}
            {...(!!date.until && idx === 0
              ? {
                  maxDate: date.until,
                }
              : {})}
          />
        ),
      )}
    </RelativeBox>
  );
};

const RelativeBox = styled(Box)`
  position: relative;
`;

const Label = styled('span')`
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  transform: translateX(-50%);
  left: 50%;
  transition: 0.2s all ease-in-out;
  bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  &.on-filled {
    top: 0;
    line-height: 1;
    font-weight: 600;
    font-size: 0.8rem;
  }
`;
