import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';

import {
  Box,
  TextField,
  IconButton,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Input,
} from '@material-ui/core';

import { FormikInput } from 'app/components/FormikInput';
import { useDialogContext } from '../Context';
import { NonResourcesForms } from 'app/containers/SettingsPage/types';
import DeleteIcon from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { actions } from '../../slice';
import { DELETED } from 'types/statuses';
import { settingsPageSelector } from '../../selectors';

export const Form = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setValidationSchema, variant, item } = useDialogContext();
  const { sublists } = useSelector(settingsPageSelector);

  const [value, setValue] = React.useState('');
  const [sublistsItems, setSublistsItems] = React.useState([] as any[]);

  React.useEffect(() => {
    dispatch(
      actions.fetchItems({
        resource: NonResourcesForms.SUBLISTS,
      }),
    );
  }, [dispatch]);

  React.useEffect(() => {
    const items = sublists
      .filter((sublist) => item.sublists.includes(sublist.id))
      .map((sublist) => ({
        value: sublist.value,
        id: sublist.id,
      }));
    setSublistsItems(items);
  }, [setSublistsItems, sublists, item]);

  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  const deleteItem = (id) => {
    dispatch(
      actions.addOrUpdateItem({
        resource: NonResourcesForms.SUBLISTS,
        item: { id, status: DELETED },
      }),
    );
  };

  const addItem = () => {
    dispatch(
      actions.addOrUpdateItem({
        resource: NonResourcesForms.SUBLISTS,
        item: { value },
      }),
    );
    setValue('');
  };

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          key: yup.string().required(t(translations.formErrors.required)),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Box display="flex">
        <FormikInput
          name="key"
          label={t(translations.ListForms.labels.name)}
          extraAttr={{
            ...variant,
            disabled: true,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
      </Box>
      <Box display="flex">
        <List>
          {sublistsItems.map((sublist) => (
            <ListItem>
              <ListItemText primary={sublist.value} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => deleteItem(sublist.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <ListItem>
            <Input
              placeholder="Valeur"
              value={value}
              onChange={onChangeHandler}
            ></Input>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={(e) => addItem()}
              >
                <Add />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
    </>
  );
};
