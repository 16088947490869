/**
 *
 * FormikInput
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { useField, useFormikContext } from 'formik';

interface Props<Component extends React.ElementType> {
  name: string;
  label: string;
  component: Component;
  forceErrorColor?: boolean;
  containerStyle?: object;
  extraAttr?: React.ComponentProps<Component>;
  noPadding?: boolean;
  noPaddingVertical?: boolean;
  noPaddingHorizontal?: boolean;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
}

export const FormikInput = ({
  forceErrorColor,
  component: Input,
  ...props
}: Props<typeof Input>) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const extraAttr = {
    label: props.label,
    variant: 'outlined',
    ...props.extraAttr,
    inputProps: {
      ...(props.extraAttr?.inputProps ?? {}),
      'aria-labelledby': `${props.name}-label`,
    },
    InputLabelProps: {
      ...(props.extraAttr?.InputLabelProps ?? {}),
      id: `${props.name}-label`,
    },
  };

  const handleChange = (value) => {
    try {
      field.onChange(value);
    } catch (e) {
      setFieldValue(field.name, value);
    }
  };

  return (
    <Div
      className={`
         ${props.noPadding ? 'no-p' : ''} 
         ${props.noPaddingVertical ? 'no-pt no-pb' : ''} 
         ${props.noPaddingHorizontal ? 'no-pl no-pr' : ''} 
         ${props.noPaddingLeft ? 'no-pl' : ''} 
         ${props.noPaddingRight ? 'no-pr' : ''} 
         ${props.noPaddingTop ? 'no-pt' : ''} 
         ${props.noPaddingBottom ? 'no-pb' : ''}
       `}
      style={props.containerStyle}
    >
      <Input
        error={(meta.error && meta.error.length > 0) || forceErrorColor}
        helperText={meta.error}
        {...field}
        {...extraAttr}
        onChange={extraAttr.onChange ? extraAttr.onChange : handleChange}
      />
    </Div>
  );
};

const Div = styled.div`
  padding: 10px;

  &.no-p {
    padding: 0;
  }
  &.no-pl {
    padding-left: 0;
  }
  &.no-pr {
    padding-right: 0;
  }
  &.no-pt {
    padding-top: 0;
  }
  &.no-pb {
    padding-bottom: 0;
  }
`;
