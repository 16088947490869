import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';

import { api } from 'utils/api';
import { updateOrAddToCollection } from 'utils/transform';
import { actions } from './slice';
import { actions as appActions } from 'app/slice';
import { settingsPageSelector } from './selectors';
import { FetchItemsParams, AddOrUpdateItemParams, Resources } from './types';
import { Image } from '../TechnicalVisitPage/types';
import { ListInterface } from 'app/types';
import { appDataSelector } from 'app/selectors';

function* fetchItems(action: PayloadAction<FetchItemsParams>) {
  try {
    const { resource, clear = false, queryParams } = action.payload;
    if (clear) {
      yield put(actions.setItems({ resource, items: [] }));
    }
    const items = yield call(api[resource].all, queryParams);
    yield put(actions.setItems({ resource, items }));
  } catch (e) {}
}
function* fetchUnifilaireFiles() {
  try {
    const items = yield call(api.files.all, 'unifilaire');
    yield put(actions.setItems({ resource: Resources.UNIFILAIRES, items }));
  } catch (e) {}
}

function* addUnifilaire({
  payload: { data, list },
}: PayloadAction<{ data: any; list: ListInterface }>) {
  try {
    yield call(api.lists.add, list);
    yield call(api.files.add, data);
    const lists = yield call(api.lists.all);
    if (lists.length > 0) yield put(appActions.setLists(lists));
  } catch (e) {}
}
function* updateUnifilaire({
  payload: { data, list },
}: PayloadAction<{ data: any; list?: ListInterface }>) {
  try {
    if (list) {
      yield call(api.lists.update, list);
    }
    yield call(api.files.update, data);
    const lists = yield call(api.lists.all);
    if (lists.length > 0) yield put(appActions.setLists(lists));
  } catch (e) {}
}
function* addOrUpdateItem<T>(action: PayloadAction<AddOrUpdateItemParams<T>>) {
  try {
    let { resource, item, key = 'id', refreshResources = [] } = action.payload;
    const apiAction =
      _.isNumber((item as any).id) || !_.isEmpty((item as any).id)
        ? 'update'
        : 'add';
    const updatedItem = yield call(api[resource][apiAction], item);
    const { [resource]: items } = yield select(settingsPageSelector);
    yield put(
      actions.setItems({
        resource,
        items: updateOrAddToCollection(items, updatedItem, key),
      }),
    );

    for (let i = 0; i <= refreshResources.length; i++) {
      yield put(actions.setItems({ resource: refreshResources[i], items: [] }));
    }
  } catch (e) {}
}

export function* settingsPageSaga() {
  yield takeEvery(actions.fetchItems, fetchItems);
  yield takeLatest(actions.addOrUpdateItem, addOrUpdateItem);
  yield takeLatest(actions.addUnifilaire, addUnifilaire);
  yield takeLatest(actions.updateUnifilaire, updateUnifilaire);
  yield takeLatest(actions.fetchUnifilaireFiles, fetchUnifilaireFiles);
}
