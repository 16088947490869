import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import { actions } from './slice';

import { Typography, Box } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormikInput } from 'app/components/FormikInput';
import { PasswordField } from 'app/components/PasswordField';
import { FloatingSubmitBtn } from 'app/components/FloatingSubmitBtn';

interface Props {
  user: UserInterface;
}

export const PasswordForm = ({ user }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCallingApi } = useSelector(appDataSelector);
  const passwordValidation = yup
    .string()
    .required(t(translations.formErrors.required))
    .matches(/^.*[a-z]+.*$/, t(translations.formErrors.lowerCase))
    .matches(/^.*[A-Z]+.*$/, t(translations.formErrors.upperCase))
    .matches(/^.*[\d]+.*$/, t(translations.formErrors.digits))
    .matches(/^.*[!@#$%^&*]+.*$/, t(translations.formErrors.specialChar))
    .min(8, t(translations.formErrors.minLength, { length: '8' }));

  return (
    <>
      {user && (
        <>
          <Typography variant="h6">
            {t(translations.UserForms.passwordTitle)}
          </Typography>

          <Formik
            initialValues={{
              password: '',
              passwordConfirm: '',
            }}
            validationSchema={yup.object({
              password: passwordValidation,
              passwordConfirm: passwordValidation.when(
                'password',
                (value, schema) =>
                  value &&
                  value.length > 0 &&
                  schema.test({
                    test: currentValue => currentValue === value,
                    message: t(translations.formErrors.samePassword),
                  }),
              ),
            })}
            onSubmit={values => {
              dispatch(
                actions.addOrUpdateUser({
                  ...user,
                  password: values.password,
                }),
              );
            }}
          >
            <Box display="flex" alignItems="center" component={Form}>
              <Box display="flex" alignItems="start">
                <FormikInput
                  name="password"
                  label={t(translations.UserForms.labels.password)}
                  extraAttr={{ variant: 'outlined' }}
                  containerStyle={{ flex: '1' }}
                  component={PasswordField}
                />
                <FormikInput
                  name="passwordConfirm"
                  label={t(translations.UserForms.labels.passwordConfirm)}
                  extraAttr={{ variant: 'outlined' }}
                  containerStyle={{ flex: '1' }}
                  component={PasswordField}
                />
              </Box>

              <FloatingSubmitBtn
                dataTestId="password-form-submit"
                disabled={isCallingApi}
              />
            </Box>
          </Formik>
        </>
      )}
    </>
  );
};
