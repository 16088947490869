import React from 'react';
import { LoadScript } from '@react-google-maps/api';

interface Props {
  children?: React.ReactElement;
}

export const GoogleMapsProvider = ({ children }: Props) => (
  <LoadScript
    googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
    libraries={['places']}
    version="3.47"
  >
    {children}
  </LoadScript>
);
