import React from 'react';
import styled from 'styled-components/macro';
import { ActionParams, ActionsIcons, RowActions } from './types';

import { IconButton, Tooltip, Icon } from '@material-ui/core';
import {
  EditRounded,
  VisibilityRounded,
  DeleteForeverRounded,
  BlockRounded,
  CheckCircleOutlined,
  RestoreFromTrashRounded,
  CancelOutlined,
  EventBusyOutlined,
  HourglassEmpty,
  History,
} from '@material-ui/icons';

const actionsIcons: ActionsIcons = {
  [RowActions.EDIT]: {
    icon: EditRounded,
    color: 'inherit',
  },
  [RowActions.SHOW]: {
    icon: VisibilityRounded,
    color: 'inherit',
  },
  [RowActions.DELETE]: {
    icon: DeleteForeverRounded,
    color: 'error',
  },
  [RowActions.HISTORY]: {
    icon: History,
    color: 'inherit',
  },
  [RowActions.DISABLE]: {
    icon: BlockRounded,
    color: 'error',
  },
  [RowActions.ENABLE]: {
    icon: CheckCircleOutlined,
    color: 'primary',
  },
  [RowActions.RESTORE]: {
    icon: RestoreFromTrashRounded,
    color: 'primary',
  },
  [RowActions.CANCEL]: {
    icon: CancelOutlined,
    color: 'error',
  },
  [RowActions.RESCHEDULE]: {
    icon: EventBusyOutlined,
    color: 'inherit',
  },
  [RowActions.REQUEST_VALIDATION]: {
    icon: HourglassEmpty,
    color: 'inherit',
  },
};

export interface Props {
  actions: ActionParams[];
  rowData: any;
  index: number;
}

type isVisibleType = Exclude<ActionParams['isVisible'], undefined>;

export const ActionsBar = function ({ actions, rowData, index }: Props) {
  const tooltipLabel = (label: ActionParams['label']): string =>
    typeof label === 'string' ? label : label(rowData);

  const isIconVisible = (isVisible: isVisibleType): boolean =>
    typeof isVisible === 'boolean' ? isVisible : isVisible(rowData);

  return (
    <>
      {actions.map(({ type, label, handler, isVisible = true }) => (
        <>
          {isIconVisible(isVisible) && (
            <Tooltip
              title={tooltipLabel(label)}
              placement="bottom"
              key={`action-icon-${type}`}
            >
              <StyledIconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handler({
                    event,
                    index,
                    rowData,
                  });
                }}
              >
                <Icon
                  fontSize="small"
                  color={actionsIcons[type].color}
                  component={actionsIcons[type].icon}
                />
              </StyledIconButton>
            </Tooltip>
          )}
        </>
      ))}
    </>
  );
};

const StyledIconButton = styled(IconButton)`
  padding: 5px !important;
`;
