import React from 'react';
import styled from 'styled-components/macro';
import { Box, Tooltip } from '@material-ui/core';
import CloudOffRounded from '@material-ui/icons/CloudOffRounded';
import SyncIcon from '@material-ui/icons/Sync';
import PauseIcon from '@material-ui/icons/Pause';

const AnimatedSyncIcon = styled(SyncIcon)`
  @keyframes circle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: circle 2s ease-in-out infinite;
`;
const blinkingKeyframe = `
  @keyframes blinking {
    0% {
      opacity: 1;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  animation: blinking 2s ease-in-out infinite;
`;

const BlinkingOfflineIcon = styled(CloudOffRounded)`
  ${blinkingKeyframe}
`;
const BlinkingPauseIcon = styled(PauseIcon)`
  ${blinkingKeyframe}
`;

interface Props {
  status: 'offline' | 'paused' | 'syncing';
  label: string;
  onForceSync: () => void;
}

export const SynchronizationStateDetails = ({
  status,
  label,
  onForceSync,
}: Props) => {
  const Icon = React.useMemo(() => {
    switch (status) {
      case 'offline':
        return BlinkingOfflineIcon;
      case 'paused':
        return BlinkingPauseIcon;
      default:
        return AnimatedSyncIcon;
    }
  }, [status]);

  return (
    <Tooltip title={label} placement="right">
      <Box
        style={{
          position: 'absolute',
          padding: '10px',
          top: '20px',
          backgroundColor: 'white',
          boxShadow: '0 2px 5px #d8d8d8',
          zIndex: 1,
          borderRadius: '0 20px 20px 0px',
        }}
        {...(status === 'paused' ? { onClick: onForceSync } : {})}
      >
        <Icon fontSize="large" color="primary" />
      </Box>
    </Tooltip>
  );
};
