import React from 'react';
import styled from 'styled-components/macro';

import { IconButton, Tooltip, SvgIcon } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

interface Props {
  Icon: typeof SvgIcon;
  tooltip: string;
  path?: string;
  className?: string;
  onClick?: () => void;
}

export const NavButton = ({
  Icon,
  tooltip,
  path,
  className,
  onClick,
}: Props) => (
  <Tooltip title={tooltip} placement="right">
    <StyledIconButton onClick={onClick} className={className}>
      {path ? (
        <StyledLink to={path}>
          <Icon color="inherit" />
        </StyledLink>
      ) : (
        <Icon color="inherit" />
      )}
    </StyledIconButton>
  </Tooltip>
);

const StyledIconButton = styled(IconButton)`
  color: rgba(255, 255, 255, 0.8) !important;

  svg {
    transition: ease-in-out all 0.2s;

    &:hover {
      filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.7));
    }
  }

  &.logout {
    color: rgba(241, 21, 21, 0.8) !important;

    svg:hover {
      filter: drop-shadow(0 0 4px rgb(241, 21, 21));
    }
  }
`;

const StyledLink = styled(NavLink)`
  color: inherit;
`;
