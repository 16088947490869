import React from 'react';
import styled from 'styled-components/macro';

import {
  IconButton,
  Icon,
  TextField,
  TextFieldProps,
  InputAdornment,
  SvgIconTypeMap,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

type Alignment = 'start' | 'end';
type SvgIcon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;

interface Props {
  alignment: Alignment;
  showAdornment: boolean;
  onResetField: () => void;
  resetIcon: SvgIcon;
}

interface AdornmentProps {
  position: Alignment;
  path?: string;
  onClick: (inputName: string) => void;
  icon: SvgIcon;
}

export const CustomDateField = ({
  alignment,
  showAdornment,
  onResetField,
  resetIcon,
  ...props
}: Props & TextFieldProps) => {
  const resetField = (inputName: string) => {
    onResetField();
  };

  return (
    <CustomTextField
      className={alignment === 'end' ? 'align-right' : ''}
      {...props}
      InputProps={{
        ...props.InputProps,
        ...(showAdornment
          ? {
              [alignment === 'start' ? 'startAdornment' : 'endAdornment']: (
                <Adornment
                  position={alignment}
                  path={props.name}
                  onClick={resetField}
                  icon={resetIcon}
                />
              ),
            }
          : {}),
      }}
    />
  );
};

const Adornment = ({ position, path = '', onClick, icon }: AdornmentProps) => {
  return (
    <InputAdornment position={position}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClick(path);
        }}
        size="small"
      >
        <Icon fontSize="small" component={icon} />
      </IconButton>
    </InputAdornment>
  );
};

const CustomTextField = styled(TextField)`
  &.align-right {
    .MuiInputLabel-shrink {
      transform-origin: top right;
      right: 0;
      text-align: right;
    }

    .MuiInput-input {
      text-align: right;
    }
  }
  .MuiInputAdornment-root {
    opacity: 0.8;
    &.MuiInputAdornment-positionStart {
      margin-right: 4px;
    }
    &.MuiInputAdornment-positionEnd {
      margin-left: 4px;
    }
    &:hover {
      opacity: 1;
    }
    .MuiIconButton-sizeSmall {
      padding: 0;
    }
  }
`;
