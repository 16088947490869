import _ from 'lodash';

export const updateOrAddToCollection = <T>(
  collection: T[],
  newResource: T,
  findingKey: keyof T,
): T[] => {
  const index = _.findIndex(collection, [findingKey, newResource[findingKey]]);
  return index === -1
    ? [...collection, newResource]
    : [
        ...collection.slice(0, index),
        newResource,
        ...collection.slice(index + 1),
      ];
};

interface Arguments<T> {
  collection: T[];
  findingKey: keyof T;
  childrenKey?: keyof SubType<T, T[] | undefined>;
  findingValues?: T[keyof T][];
  resource?: T;
  callback?: (item: T) => Partial<T>;
}

export const updateOrAddToCollectionDeep = <T>({
  collection,
  findingKey,
  childrenKey,
  resource = {} as T,
  callback = () => ({}),
  findingValues = [resource[findingKey]],
}: Arguments<T>): T[] => {
  const collectionCopy = JSON.parse(JSON.stringify(collection));

  let currentIndex = _.findIndex(collection, [findingKey, findingValues[0]]);

  if (findingValues.length > 1) {
    collectionCopy[currentIndex][
      childrenKey as string
    ] = updateOrAddToCollectionDeep({
      collection: collectionCopy[currentIndex][childrenKey as string],
      findingKey,
      childrenKey,
      findingValues: findingValues.slice(1),
      resource,
      callback,
    });
  } else {
    currentIndex = currentIndex === -1 ? collectionCopy.length : currentIndex;
    collectionCopy[currentIndex] = {
      ...(collectionCopy[currentIndex] || {}),
      ...resource,
      ...callback(collectionCopy[currentIndex]),
    };
  }

  return collectionCopy;
};

type SubType<Base, Condition> = Pick<
  Base,
  {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
  }[keyof Base]
>;
