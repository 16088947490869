import { getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { forceReducerReload } from 'redux-injectors';
import { persistStore } from 'redux-persist';
import reducers from './reducers';
import rootSaga from './sagas';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [sagaMiddleware];
  const { run: runSaga } = sagaMiddleware;

  const store = configureStore({
    reducer: reducers,
    middleware: [
      ...getDefaultMiddleware({ serializableCheck: false }),
      ...middlewares,
    ],
    devTools: true,
    // devTools:
    //   process.env.NODE_ENV !== 'production' ||
    //   process.env.PUBLIC_URL.length > 0,
  });

  runSaga(rootSaga);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  return { store, persistor: persistStore(store) };
}
