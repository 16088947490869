import React from 'react';
import { Box, Fab } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';

interface Props {
  disabled?: boolean;
  dataTestId?: string;
}

export const FloatingSubmitBtn = ({ disabled, dataTestId }: Props) => (
  <Box flexShrink="1">
    <Fab
      type="submit"
      color="primary"
      disabled={disabled}
      data-testid={dataTestId}
    >
      <SaveIcon />
    </Fab>
  </Box>
);
