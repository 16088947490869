import { createTheme } from '@material-ui/core';

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#F3920A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ba6400',
    },
    background: {
      default: '#F5F5F5',
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#F3920A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ba6400',
    },
    background: {
      default: 'black',
    },
  },
});
