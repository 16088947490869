export enum Statuses {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DELETED = 'deleted',
  PENDING = 'pending',
}

export enum States {
  PENDING = 'pending',
  PLANIFIED = 'planified',
  VALIDATED = 'validated',
  REFUSED = 'refused',
  CANCELED = 'canceled',
  TO_RESCHEDULE = 'toReschedule',
  REALIZED = 'realized',
  TECHNICAL_INFEASIBILITY = 'technicalInfeasibility',
  TO_REVIEW = 'toReview',
}

export const {
  ENABLED,
  DISABLED,
  DELETED,
  PENDING,
  PLANIFIED,
  VALIDATED,
  REFUSED,
  CANCELED,
  TO_RESCHEDULE,
  REALIZED,
  TECHNICAL_INFEASIBILITY,
  TO_REVIEW,
} = { ...Statuses, ...States };
