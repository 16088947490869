import { PayloadAction } from '@reduxjs/toolkit';
import { SetFiltersParams, SetItemsParams } from 'app/types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  FetchFiltersParams,
  Resources,
  DeleteItemParams,
  SetHistoryParams,
} from './types';

// The initial state of the DashboardPage container
export const initialState: ContainerState = {
  users: [],
  history: {},
  filters: {
    technicalVisits: {
      page: 0,
      sortBy: 'appointmentAt',
      sortDirection: 'DESC',
      filter: { appointmentAt: [null, null] },
      fetchNextPage: false,
    },
  },
};
const dashboardPageSlice = createSlice({
  name: 'dashboardPage',
  initialState,
  reducers: {
    resetResources(state) {
      Object.values(Resources).forEach((resource) => (state[resource] = []));
    },

    fetchFilters(state, action: PayloadAction<FetchFiltersParams>) {},
    setItems(state, action: PayloadAction<SetItemsParams>) {
      const { resource, items } = action.payload;
      state[resource].items = items as any;
    },
    setItemsFilter(state, action: PayloadAction<SetItemsParams>) {
      const { resource, items } = action.payload;
      state[resource] = items as any;
    },
    setFilters(state, action: PayloadAction<SetFiltersParams>) {
      const { filterName, filterData } = action.payload;
      state.filters[filterName] = {
        ...state.filters[filterName],
        filter: filterData.filter,
      } as any;
    },
    deleteItem<T>(state, action: PayloadAction<DeleteItemParams>) {},
    fetchHistory(state, action: PayloadAction<Object>) {},
    setHistory(
      state,
      { payload: { collection, history } }: PayloadAction<SetHistoryParams>,
    ) {
      state.history[collection] = history;
    },
    unsetHistory(state, action: PayloadAction<string>) {
      delete state.history[action.payload];
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardPageSlice;
