import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  AddOrUpdateItemParams,
  ContainerState,
  TechnicalVisitPageState,
  SetItemParams,
  ResourcePushes,
  AddOrUpdateImageParams,
  SetImagesParams,
  DeleteImageParams,
  RemoveItemParams,
  ResourceSplices,
  ExportImagesParams,
} from './types';
import { technicalVisit as initialTechnicalVisit } from './initialStates';
import { customer as initialCustomer } from './initialStates';
import { electricity as initialElectricity } from './initialStates';
import { generator as initialGenerator } from './initialStates';
import { documentDt as initialdocumentDt } from './initialStates';
import _ from 'lodash';

// The initial state of the DashboardPage container
export const initialState: ContainerState = {
  technicalVisit: initialTechnicalVisit,
  customer: initialCustomer,
  roofFrame: [],
  electricity: initialElectricity,
  generator: initialGenerator,
  radiator: [],
  equipmentHandles: [],
  selfConsumption: [],
  documentDt: initialdocumentDt,
  images: {},
};
const technicalVisitPageSlice = createSlice({
  name: 'technicalVisitPage',
  initialState,
  reducers: {
    setTechnicalVisitId(state, action: PayloadAction<string>) {
      state.technicalVisitId = action.payload;
    },
    resetState(state) {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
    fetchTechnicalVisit(state, action: PayloadAction<string>) {},
    generatePdf(state, action: PayloadAction<any>) {},
    setTechnicalVisit(state, action: PayloadAction<TechnicalVisitPageState>) {
      const {
        customer,
        roofFrame,
        technicalVisit,
        electricity,
        generator,
        radiator,
        equipmentHandles,
        documentDt,
        selfConsumption,
        images,
      } = action.payload;
      state.customer = customer;
      state.technicalVisit = technicalVisit;
      state.roofFrame =
        roofFrame.length > 0 ? roofFrame : initialState.roofFrame;
      state.electricity = electricity || initialElectricity;
      state.generator = generator || initialGenerator;
      state.radiator = radiator.length > 0 ? radiator : initialState.radiator;
      state.equipmentHandles =
        equipmentHandles.length > 0
          ? equipmentHandles
          : initialState.equipmentHandles;
      state.selfConsumption =
        selfConsumption.length > 0
          ? selfConsumption
          : initialState.selfConsumption;
      state.documentDt = documentDt || initialState.documentDt;
      state.images = images || state.images || initialState.images;
    },
    addOrUpdateItem<T>(
      state,
      action: PayloadAction<AddOrUpdateItemParams<T>>,
    ) {},
    removeItem(state, action: PayloadAction<RemoveItemParams>) {},
    setItemResource(state, action: PayloadAction<SetItemParams>) {
      const { resource, item, index } = action.payload;
      if (index === undefined) state[resource] = item as any;
      else state[resource][index] = item as any;
    },
    unsetItemResource(
      state,
      {
        payload: { resource, id },
      }: PayloadAction<{ resource: string; id: string | number }>,
    ) {
      if (_.isArray(state[resource])) {
        const index = state[resource].findIndex(
          ({ id: itemId }) => itemId === id,
        );
        state[resource].splice(index, 1);
      }
    },
    pushResource(state, action: PayloadAction<ResourcePushes>) {
      (state[action.payload.resource] as Array<any>).push(
        action.payload.values,
      );
    },
    spliceResource(state, action: PayloadAction<ResourceSplices>) {
      (state[action.payload.resource] as Array<any>).splice(
        action.payload.index,
        1,
      );
    },
    fetchImages(state, action: PayloadAction<string>) {},

    setImages(
      state,
      { payload: { collection, images } }: PayloadAction<SetImagesParams>,
    ) {
      state.images[collection] = images;
    },
    unsetImages(state, action: PayloadAction<string>) {
      delete state.images[action.payload];
    },
    addOrUpdateImage(state, action: PayloadAction<AddOrUpdateImageParams>) {},
    deleteImage(state, action: PayloadAction<DeleteImageParams>) {},
    exportImages(state, action: PayloadAction<ExportImagesParams>) {},
  },
});

export const { actions, reducer, name: sliceKey } = technicalVisitPageSlice;
