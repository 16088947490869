import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';

import { api } from 'utils/api';
import { updateOrAddToCollection } from 'utils/transform';
import { UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import { actions as appActions } from 'app/slice';
import { actions } from './slice';
import { settingsPageSelector } from '../SettingsPage/selectors';
import { actions as settingsPageActions } from '../SettingsPage/slice';
import { Resources } from '../SettingsPage/types';

// function* fetchCountriesCodes() {
//   try {
//     yield put(actions.setCountriesCodes(countriesCodes));
//   } catch (e) {}
// }

function* addOrUpdateUser(action: PayloadAction<Partial<UserInterface>>) {
  const apiAction = _.isEmpty(action.payload.id) ? 'add' : 'update';
  const { currentUser } = yield select(appDataSelector);
  const user: UserInterface = yield call(api.users[apiAction], action.payload);
  const { users } = yield select(settingsPageSelector);
  yield put(
    settingsPageActions.setItems({
      resource: Resources.USERS,
      items: updateOrAddToCollection(users, user, 'id'),
    }),
  );

  if (currentUser.id === user.id) {
    yield put(appActions.setCurrentUser(user));
  }
}

export function* userDialogSaga() {
  // yield takeLatest(actions.fetchCountriesCodes.type, fetchCountriesCodes);
  yield takeLatest(actions.addOrUpdateUser.type, addOrUpdateUser);
}
