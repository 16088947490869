import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';
import {
  Box,
  Button,
  FormControlLabel,
  Dialog,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { RoofFrameInterface } from '../types';

export const Form = () => {
  const { values, setFieldValue } = useFormikContext<RoofFrameInterface>();
  const { t } = useTranslation();
  const { setValidationSchema, variant } = useStepperContext();
  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );

  const [openModal, setOpenModal] = React.useState(false);
  const [listKeys, setListKeys] = React.useState([
    'orientation',
    'installation_type',
    'covering_type',
    'roof_tiles',
    'frame_type',
    'frame_materials',
    'general_condition_of',
    'sealed_or_unsealed',
    'roof_slate',
    'metal_bin',
  ]);

  React.useEffect(() => {
    const options = {};
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);

  const checkHasSublists = (id, field, listKey) => {
    setFieldValue(field, id);
    const sublist = getSublist(id, listKey);

    if (sublist?.hasSublist) {
      setListKeys([...listKeys, sublist.key]);
    }
  };

  const sunMaskValues = [
    'minusOneHundredEighty',
    'minusOneHundredFifty',
    'minusOneHundredTwenty',
    'minusNinety',
    'minusSixty',
    'minusFortyFive',
    'minusThirty',
    'zero',
    'oneHundredFifty',
    'oneHundredTwenty',
    'ninety',
    'sixty',
    'fortyFive',
    'thirty',
  ];

  const getSublist = (id, listKey) =>
    lists.find((l) => l.key === listKey)?.sublists?.find((sl) => sl.id === id);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          orientation: yup
            .number()
            .required(t(translations.formErrors.required)),
          degreesOrientation: yup
            .number()
            .required(t(translations.formErrors.required))
            .typeError(t(translations.formErrors.onlyDigits)),
          pitch: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          roofEdgeHeight: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          installationType: yup.number().nullable(),
          roofType: yup.number().nullable(),
          roofSubType: yup.number().nullable(),
          frameType: yup.number().nullable(),
          frameMaterial: yup.number().nullable(),
          roofThickness: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          centerDistance: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          height: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          conditionOfFrame: yup.number().nullable(),
          conditionOfRoof: yup.number().nullable(),
          ridgeType: yup.number().nullable(),
          shoreType: yup.number().nullable(),
          battenThickness: yup.string().nullable(),
          frameLength: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          crawlingFrame: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          furtherInformation: yup.string().nullable(),
          obstacleOnRoof: yup.string().nullable(),
          enoughMaterialAvailable: yup.boolean().nullable(),
          insulationBetween: yup.boolean().nullable(),
          minusOneHundredEighty: yup.string().nullable(),
          minusOneHundredFifty: yup.string().nullable(),
          minusOneHundredTwenty: yup.string().nullable(),
          minusNinety: yup.string().nullable(),
          minusSixty: yup.string().nullable(),
          minusFortyFive: yup.string().nullable(),
          minusThirty: yup.string().nullable(),
          zero: yup.string().nullable(),
          oneHundredFifty: yup.string().nullable(),
          oneHundredTwenty: yup.string().nullable(),
          ninety: yup.string().nullable(),
          sixty: yup.string().nullable(),
          fortyFive: yup.string().nullable(),
          thirty: yup.string().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getThick = (v) => {
    if (v === 'tuiles') {
      return 'roofThicknessBetweenC';
    } else if (v === 'fibrociment') {
      return 'roofThicknessBetween';
    } else return 'roofThickness';
  };
  return (
    <>
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="orientation"
              label={t(translations.RoofFrameForms.labels.orientation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['orientation'] || [],
                displayEmpty: false,
              }}
              containerStyle={{ flex: '3' }}
              component={SimpleSelect}
            />
            <FormikInput
              name="degreesOrientation"
              label={t(translations.RoofFrameForms.labels.degreesOrientation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                onChange: (e) =>
                  setFieldValue(
                    'degreesOrientation',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '4' }}
              component={TextField}
            />
            <FormikInput
              name="pitch"
              label={t(translations.RoofFrameForms.labels.pitch)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                onChange: (e) =>
                  setFieldValue(
                    'pitch',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '4' }}
              component={TextField}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="roofEdgeHeight"
              label={t(translations.RoofFrameForms.labels.roofEdgeHeight)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                onChange: (e) =>
                  setFieldValue(
                    'roofEdgeHeight',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '5' }}
              component={TextField}
            />
            <FormikInput
              name="installationType"
              label={t(translations.RoofFrameForms.labels.installationType)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['installation_type'] || [],
                //    onChange: (e) => checkHasSublists(e.target.value),
              }}
              containerStyle={{ flex: '4' }}
              component={SimpleSelect}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="conditionOfRoof"
              label={t(translations.RoofFrameForms.labels.conditionOfRoof)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['general_condition_of'] || [],
              }}
              containerStyle={{ flex: '2' }}
              component={SimpleSelect}
            />
            <FormikInput
              name="roofType"
              label={t(translations.RoofFrameForms.labels.roofType)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions.covering_type || [],
                onChange: (e) =>
                  checkHasSublists(e.target.value, 'roofType', 'covering_type'),
              }}
              containerStyle={{ flex: '4' }}
              component={SimpleSelect}
            />
            {values.roofType &&
              getSublist(values.roofType, 'covering_type')?.key && (
                <FormikInput
                  name="roofSubType"
                  label={`${t(translations.RoofFrameForms.labels.roofSubType)} 
              ${getSublist(values.roofType, 'covering_type')?.name}`}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    style: { width: '100%' },
                    options:
                      listsOptions[
                        getSublist(values.roofType, 'covering_type')!.key
                      ] || [],
                  }}
                  containerStyle={{ flex: '4' }}
                  component={SimpleSelect}
                />
              )}
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="conditionOfFrame"
              label={t(translations.RoofFrameForms.labels.conditionOfFrame)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['general_condition_of'] || [],
              }}
              containerStyle={{ flex: '2' }}
              component={SimpleSelect}
            />

            <FormikInput
              name="frameType"
              label={t(translations.RoofFrameForms.labels.frameType)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['frame_type'] || [],
              }}
              containerStyle={{ flex: '4' }}
              component={SimpleSelect}
            />

            <FormikInput
              name="frameMaterial"
              label={t(translations.RoofFrameForms.labels.frameMaterial)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['frame_materials'] || [],
              }}
              containerStyle={{ flex: '4' }}
              component={SimpleSelect}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="frameLength"
              label={t(translations.RoofFrameForms.labels.frameLength)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                onChange: (e) =>
                  setFieldValue(
                    'frameLength',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
            <FormikInput
              name="crawlingFrame"
              label={t(translations.RoofFrameForms.labels.crawlingFrame)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                onChange: (e) =>
                  setFieldValue(
                    'crawlingFrame',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="roofThickness"
              label={`${t(
                translations.RoofFrameForms.labels[
                  !values.frameType
                    ? 'roofThickness'
                    : getThick(
                        getSublist(values.roofType, 'covering_type')?.name,
                      )
                ],
              )}`}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                onChange: (e) =>
                  setFieldValue(
                    'roofThickness',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="centerDistance"
              label={`${t(translations.RoofFrameForms.labels.centerDistance, {
                item: getSublist(values.frameType, 'frame_type')
                  ? getSublist(values.frameType, 'frame_type')?.name?.split(
                      ' ',
                    )?.[0]
                  : '',
              })}`}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                onChange: (e) =>
                  setFieldValue(
                    'centerDistance',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
            <FormikInput
              name="height"
              label={`${t(translations.RoofFrameForms.labels.height, {
                item: getSublist(values.frameType, 'frame_type')
                  ? getSublist(values.frameType, 'frame_type')?.name?.split(
                      ' ',
                    )?.[0]
                  : '',
              })}`}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                onChange: (e) =>
                  setFieldValue(
                    'height',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="ridgeType"
              label={t(translations.RoofFrameForms.labels.ridgeType)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['sealed_or_unsealed'] || [],
              }}
              containerStyle={{ flex: '2' }}
              component={SimpleSelect}
            />
            <FormikInput
              name="shoreType"
              label={t(translations.RoofFrameForms.labels.shoreType)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                options: listsOptions['sealed_or_unsealed'] || [],
              }}
              containerStyle={{ flex: '2' }}
              component={SimpleSelect}
            />{' '}
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="battenThickness"
              label={t(translations.RoofFrameForms.labels.battenThickness)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                style: { width: '100%' },
                onChange: (e) =>
                  setFieldValue(
                    'battenThickness',
                    e.target.value ? e.target.value.replaceAll(',', '.') : null,
                  ),
              }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="enoughMaterialAvailable"
              label={t(
                translations.RoofFrameForms.labels.enoughMaterialAvailable,
              )}
              extraAttr={{
                labelPlacement: 'bottom',
                disabled: isSupplier,
                checked: values['enoughMaterialAvailable'],
                control: <Switch size="small" />,
              }}
              containerStyle={{
                flex: '2',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={FormControlLabel}
            />
            <FormikInput
              name="insulationBetween"
              label={t(translations.RoofFrameForms.labels.insulationBetween)}
              extraAttr={{
                disabled: isSupplier,
                labelPlacement: 'bottom',
                checked: values['insulationBetween'],
                control: <Switch size="small" />,
              }}
              containerStyle={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={FormControlLabel}
            />
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flex={6}
            flexDirection={onSmallDevice ? 'column' : 'row'}
          >
            <FormikInput
              name="furtherInformation"
              label={t(translations.RoofFrameForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 6,
                multiline: true,
                style: { width: '100%' },
              }}
              containerStyle={{ flex: '6' }}
              component={TextField}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="obstacleOnRoof"
              label={t(translations.RoofFrameForms.labels.obstacleOnRoof)}
              extraAttr={{
                ...variant,
                rows: 6,
                multiline: true,
                disabled: isSupplier,
              }}
              containerStyle={{ flex: '4' }}
              component={TextField}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={onSmallDevice ? 'column' : 'row'}
            justifyContent="space-around"
          >
            <Button
              onClick={() => setOpenModal(true)}
              variant="contained"
              color="secondary"
              size="small"
            >
              {t(translations.RoofFrameForms.labels.haveSunMask)}
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '100px' }} align="left">
                  Azimut
                </TableCell>
                <TableCell align="center">-180</TableCell>
                <TableCell align="center">-150</TableCell>
                <TableCell align="center">-120</TableCell>
                <TableCell align="center">-90</TableCell>
                <TableCell align="center">-60</TableCell>
                <TableCell align="center">-45</TableCell>
                <TableCell align="center">-30</TableCell>
                <TableCell align="center">0</TableCell>
                <TableCell align="center">30</TableCell>
                <TableCell align="center">45</TableCell>
                <TableCell align="center">60</TableCell>
                <TableCell align="center">90</TableCell>
                <TableCell align="center">120</TableCell>
                <TableCell align="center">150</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <span>Hauteur</span>
                </TableCell>
                {sunMaskValues.map((sunMask) => (
                  <TableCell>
                    <FormikInput
                      name={sunMask}
                      label={''}
                      extraAttr={{
                        ...variant,
                        disabled: isSupplier,
                        style: { width: '60px', padding: '18px 0px' },
                        onChange: (e) =>
                          setFieldValue(
                            sunMask,
                            e.target.value
                              ? e.target.value.replaceAll(',', '.')
                              : null,
                          ),
                      }}
                      containerStyle={{ flex: '2' }}
                      component={TextField}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          flexDirection={onSmallDevice ? 'column' : 'row'}
          padding={'10px'}
          justifyContent="space-around"
        >
          <Button
            onClick={() => setOpenModal(false)}
            variant="contained"
            color="secondary"
          >
            {t(translations.TechnicalVisitForms.buttons.close)}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
