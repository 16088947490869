import React from 'react';
import styled from 'styled-components/macro';

import { IconButton } from '@material-ui/core';

export interface Props extends React.ComponentProps<typeof IconButton> {
  ariaLabel: string;
  rotate?: boolean;
  backgroundColor?: string;
}
export const AnimatableButton = ({
  ariaLabel,
  rotate = false,
  backgroundColor,
  ...props
}: Props) => {
  return (
    <AnimatableIconBtn
      size="small"
      color="secondary"
      aria-label={ariaLabel}
      className={[
        rotate && 'rotate',
        backgroundColor && 'with-background',
      ].join(' ')}
      style={{ backgroundColor }}
      {...props}
    />
  );
};

const AnimatableIconBtn = styled(IconButton)`
  padding: 5px;
  &:not(:last-child) {
    margin-right: 3px !important;
  }

  &.rotate {
    animation: spin 2s linear infinite;
  }
  &.with-background:disabled {
    background-color: #f5f5f55e !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
`;
