/**
 *
 * UserDialog
 *
 */

import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import { actions, reducer, sliceKey } from './slice';
import { actions as appActions } from 'app/slice';
import { selectUserDialog } from './selectors';
import { userDialogSaga } from './saga';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { DetailsForm } from './DetailsForm';
import { PasswordForm } from './PasswordForm';

interface Props {
  isOpen: boolean;
  user: UserInterface;
  offline: boolean;
  onClose: () => void;
  asProfile?: boolean;
}

export const UserDialog = ({
  isOpen,
  user,
  offline,
  onClose,
  asProfile = false,
}: Props) => {
  // useInjectReducer({ key: sliceKey, reducer: reducer });
  // useInjectSaga({ key: sliceKey, saga: userDialogSaga });
  const dispatch = useDispatch();
  const { countriesCodes } = useSelector(selectUserDialog);
  const { isSuperadmin, isAdmin, currentUser } = useSelector(appDataSelector);
  React.useEffect(() => {
    _.isEmpty(countriesCodes) && dispatch(actions.fetchCountriesCodes());
  }, [dispatch, countriesCodes]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <DetailsForm
          user={user}
          offline={offline}
          withAdminDetails={!asProfile && (isSuperadmin || isAdmin)}
          onForceCloseDialog={onClose}
        />

        {!_.isEmpty(user.id) && !offline && (
          <>
            <StyledDivider />
            <PasswordForm user={user} />
          </>
        )}
        {currentUser.id === user?.id && (
          <>
            <StyledDivider />

            <Box display="flex" justifyContent="space-between" marginY="1em">
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => dispatch(appActions.exportedCachedData())}
                disabled={offline}
              >
                Forcer l'envoi mes données
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() =>
                  dispatch(appActions.exportedCachedData({ download: true }))
                }
              >
                Télécharger mes données
              </Button>
            </Box>

            <Typography variant="caption" color="textSecondary">
              Version: {process.env.REACT_APP_VERSION || 'developement'}
            </Typography>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const StyledDivider = styled(Divider)`
  margin: 20px 0 !important;
`;
