import {
  SortDirectionType,
  TableCellProps,
  TableProps as VirtualizedTableProps,
} from 'react-virtualized';
import { SvgIcon, IconTypeMap } from '@material-ui/core';

export enum RowActions {
  EDIT = 'edit',
  SHOW = 'show',
  DELETE = 'delete',
  DISABLE = 'disable',
  ENABLE = 'enable',
  RESTORE = 'restore',
  CANCEL = 'cancel',
  RESCHEDULE = 'reschedule',
  REQUEST_VALIDATION = 'requestValidation',
  HISTORY = 'history',
}
export enum TableActions {
  EXPORT = 'export',
}

export enum ColumnVariant {
  BODY = 'body',
  FOOTER = 'footer',
  HEAD = 'head',
}
export type OnClickHandler = (params: RowClickParams) => void;

export type CellRendererMethod = () => JSX.Element;

export interface ColumnData {
  dataKey: string;
  width?: number;
  widthRatio?: number;
  label?: JSX.Element | string | (() => JSX.Element);
  sortable?: any;
  isNumeric?: boolean;
  isDate?: boolean;
  isBoolean?: boolean;
  isSortable?: boolean;
  sortDirection?: SortDirectionType;
  sortBy?: string;
  isVisible?: boolean;
  cellRenderer?: (
    datas: TableCellProps,
  ) => JSX.Element | string | (() => JSX.Element);
  extraStyle?: any;
}

export interface Row {
  index: number;
}

export interface RowClickParams {
  event: Event | any;
  rowData: any;
  index: number;
}

interface ActionIcon {
  icon: typeof SvgIcon;
  color: Exclude<IconTypeMap['props']['color'], 'default'>;
}

export type ActionsIcons = {
  [key in RowActions]: ActionIcon;
};

export interface ActionParams {
  type: RowActions;
  label: ((rowData: any) => string) | string;
  handler: OnClickHandler;
  isVisible?: ((rowData: any) => boolean) | boolean;
}

export interface ActionsParams {
  icons: ActionParams[];
  maxVisibleIcons?: number;
}
export interface SortParams {
  sortBy: string;
  sortDirection: SortDirectionType;
}

export interface TableProps
  extends Partial<
      Pick<
        VirtualizedTableProps,
        | 'onRowClick'
        | 'headerHeight'
        | 'rowHeight'
        | 'onScroll'
        | 'rowStyle'
        | 'sort'
        | 'onRowsRendered'
      >
    >,
    Pick<VirtualizedTableProps, 'rowCount' | 'rowGetter'> {
  columns: ColumnData[];
  displayedColumns?: string[];
  initialSortParams?: SortParams;
  actionsParams?: ActionsParams;
  hasHorizontalScroll?: boolean;
  scrollTop?: boolean;
}
