import React from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import {} from '@react-google-maps/marker-clusterer';
import { format } from 'date-fns';

import { TechnicalVisitDataInterface } from 'app/types';

import { Box } from '@material-ui/core';
import { rainbow } from 'utils/rainbow-color-picker';
import { appDataSelector } from 'app/selectors';
interface VisitMarker extends TechnicalVisitDataInterface {
  position: { lat: number; lng: number };
}

export const EventMap = () => {
  const {
    technicalVisit: { items },
  } = useSelector(appDataSelector);
  const [markers, setMarkers] = React.useState<VisitMarker[]>();
  const [activeMarker, setActiveMarker] = React.useState<string>();
  const [technicianMarkersColors, setTechnicianMarkerColors] = React.useState<{
    [key: string]: string;
  }>({});

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
    const zoom = map.getZoom();
    zoom && zoom > 17 && map.setZoom(17);
  };

  const setMarkersWithPinColor = React.useCallback(() => {
    const technicians: string[] = [];
    const markers = items
      .filter(({ customer: { coordinates } }) => !!coordinates)
      .map((item) => {
        !technicians.includes(item.technicalVisit.technicianName!) &&
          technicians.push(item.technicalVisit.technicianName!);

        return {
          ...item,
          position: item.customer.coordinates!.split(',').reduce(
            (acc, v, i) => ({
              ...acc,
              [i === 0 ? 'lat' : 'lng']: parseFloat(v),
            }),
            {} as { lat: number; lng: number },
          ),
        };
      });

    const colors = rainbow(technicians.length);
    setTechnicianMarkerColors(
      technicians.reduce(
        (acc, technician, i) => ({
          ...acc,
          [technician]: colors[i],
        }),
        {},
      ),
    );
    setMarkers(markers);
  }, [setMarkers, items]);

  React.useEffect(() => {
    setMarkersWithPinColor();
  }, [setMarkersWithPinColor, items]);

  return (
    <>
      {markers && (
        <Box display="flex" flexGrow={1}>
          <GoogleMap
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'transit',
                  stylers: [{ visibility: 'off' }],
                },
              ],
            }}
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(undefined)}
            mapContainerStyle={{
              width: '100%',
            }}
          >
            {markers.map(
              (
                {
                  position,
                  technicalVisit: { id, appointmentAt, technicianName },
                  customer: {
                    firstname,
                    lastname,
                    address,
                    zip,
                    city,
                    coordinates,
                  },
                },
                i,
              ) => (
                <Marker
                  key={id}
                  position={position}
                  onClick={() => setActiveMarker(id)}
                  icon={{
                    anchor: new google.maps.Point(10, 24),
                    path:
                      'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                    fillColor: technicianMarkersColors[technicianName!],
                    fillOpacity: 1,
                    scale: 1.5,
                    strokeColor: 'black',
                    strokeWeight: 1,
                  }}
                >
                  {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(undefined)}>
                      <Box>
                        <Box>
                          <b>Technicien: </b>
                          {technicianName}
                        </Box>
                        <Box>
                          <b>Date: </b>
                          {format(new Date(appointmentAt), 'dd/MM/yy, HH:MM')}
                        </Box>
                        <Box>
                          <b>Nom: </b>
                          {lastname} {firstname}
                        </Box>
                        <Box>
                          <b>Adresse: </b>
                          {address
                            ? [
                                address,
                                [zip, city]
                                  .filter((v) => v && `${v}`.length > 0)
                                  .join(' '),
                              ]
                                .filter((v) => v && v.length > 0)
                                .join(' - ')
                            : coordinates}
                        </Box>
                      </Box>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ),
            )}
          </GoogleMap>
        </Box>
      )}
    </>
  );
};
