import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import {
  Resources,
  ResourceDialogParams,
  NonResourcesForms as Forms,
} from '../types';
import { ListForm } from './Forms';

interface Props {
  children?: JSX.Element;
  item: ResourceDialogParams['item'];
  readOnly: boolean;
}

interface ContextInterface {
  validationSchema: yup.ObjectSchema<any, any, any, any>;
  setValidationSchema: (schema: yup.ObjectSchema<any, any, any, any>) => void;
  resourceForms: {
    [key in Extract<Resources, Resources.LISTS>]: JSX.Element;
  };
  digitsValidation: yup.StringSchema;
  digitsValidationRequired: yup.StringSchema;
  variant: { variant: string };
  item: ResourceDialogParams['item'];
  readOnly: boolean;
}

const Context = React.createContext<ContextInterface>({} as ContextInterface);
export const useDialogContext = () => React.useContext(Context);

export const DialogContext = {
  Consumer: Context.Consumer,
  Provider: ({ children, item, readOnly }: Props) => {
    const { t } = useTranslation();
    const [validationSchema, setValidationSchema] = React.useState(
      {} as yup.ObjectSchema<any, any, any, any>,
    );
    const digitsValidation = yup
      .string()
      .matches(/^[\d]+$/, t(translations.formErrors.onlyDigits));

    return (
      <Context.Provider
        value={{
          validationSchema,
          setValidationSchema,
          digitsValidation,
          digitsValidationRequired: digitsValidation.required(
            t(translations.formErrors.required),
          ),
          resourceForms: {
            [Resources.LISTS]: <ListForm />,
          },
          variant: { variant: 'outlined' },
          item,
          readOnly,
        }}
      >
        {children}
      </Context.Provider>
    );
  },
};
