/**
 *
 * StandardDialog
 *
 */

import React from 'react';
import styled from 'styled-components/macro';

import CloseIcon from '@material-ui/icons/Close';
import {
  IconButton,
  Box,
  DialogContent,
  Dialog,
  withTheme,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

interface Props {
  onClose: () => void;
  title?: string;
  children: any;
  isOpen: boolean;
  classes: any;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // margin: 0,
      // padding: theme.spacing(2),
    },
    closeButton: {
      // position: 'absolute',
      // right: theme.spacing(1),
      // top: theme.spacing(1),
      // color: theme.palette.grey[500],
    },
  });

export const StandardDialog = withStyles(styles)(
  ({ children, title, onClose, isOpen, classes }: Props) => (
    <Dialog open={isOpen} maxWidth="lg">
      {/* <MuiDialogTitle className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle> */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        padding="4px"
      >
        <CloseButton
          aria-label="close"
          // className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </CloseButton>
      </Box>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  ),
);

const CloseButton = withTheme(styled(IconButton)`
  color: ${({ theme }) => theme.palette.error.light} !important;
  &:hover {
    color: ${({ theme }) => theme.palette.error.main} !important;
  }
`);
