import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { appDataInitialState as initialState } from './types';

const selectDomain = (state: RootState) => state.appData || initialState;

export const appDataSelector = createSelector(
  [selectDomain],
  (appDataState) => appDataState,
);
