import React from 'react';
import localforage from 'localforage';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { compress, compressAccurately, EImageType } from 'image-conversion';

import { API_URL } from 'utils/api';
import { getFileExtension } from 'utils/file-extension';
import { appDataSelector } from 'app/selectors';
import { technicalVisitPageSelector } from '../selectors';
import { actions, initialState } from '../slice';

import {
  Box,
  Drawer,
  Fab,
  Tooltip,
  ImageList,
  ImageListItem,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  PhotoAlbum as GalleryIcon,
  Close as CloseIcon,
  Delete,
  Edit,
  Save,
  Close,
  CameraAlt,
  Fullscreen,
  ExpandMore,
} from '@material-ui/icons';
import { CircularProgress } from 'app/components/CircularProgress';
import { startCase } from 'lodash';
import { Image } from '../types';
import { FilePreview } from './FilePreview';

localforage.config({ name: 'files' });

export interface Collection {
  path: string;
  name?: string;
}
interface Props {
  collections: Collection[];
  resource: { name: string; label: string };
  canEdit: boolean;
}

interface UpdateHandlerParams {
  title?: string;
  file?: File;
}

export const ImagesCollection = ({ collections, resource, canEdit }: Props) => {
  const dispatch = useDispatch();
  const {
    isCallingApi,
    technicalVisitsCached,
    isAdmin,
    currentUser,
    isBasic,
    isSuperadmin,
  } = useSelector(appDataSelector);
  const { technicalVisitId } = useSelector(technicalVisitPageSelector);
  const [isOpen, setOpen] = React.useState(true);
  const [fullscreenFileId, setFullscreenFile] = React.useState<string>();
  const [
    currentlyUpdatingFileId,
    setCurrentlyUpdatingFile,
  ] = React.useState<string>();
  const [failingImages, setFailingImages] = React.useState<string[]>([]);
  const [expandedGallery, setExpandedGallery] = React.useState<string>(
    collections[0].path,
  );

  const { images = {}, ...items } = React.useMemo(
    () =>
      technicalVisitId && technicalVisitsCached[technicalVisitId]
        ? technicalVisitsCached[technicalVisitId]
        : initialState,
    [technicalVisitsCached, technicalVisitId],
  );

  const handleDelete = (
    id: string,
    path: string,
    title: string,
    name?: string,
  ) => {
    dispatch(
      actions.deleteImage({
        id,
        collections: [path],
        data: {
          resourceName: resource.name,
          resourceId: path.split('/').pop(),
          resourceNameTv: 'technical-visits',
          resourceIdTv: technicalVisitId!,
          title: name || decodeURIComponent(title) || resource.label,
        },
      }),
    );
  };

  const handleUpdate = async (
    id: string,
    { title, file }: UpdateHandlerParams,
    path,
    name,
  ) => {
    const splittedPath = path.split('/');
    const resourceId = splittedPath.pop();
    const data: Partial<Image> = {
      id,
      resourceName: resource.name,
      resourceId,
      resourceNameTv: 'technical-visits',
      resourceIdTv: technicalVisitId!,
      title: `${collections.length > 1 ? name : resource.label} : ${title}`,
      path,
    };
    if (title) data.name = title;

    if (file) {
      // const bufferedFile = await file.arrayBuffer();
      // console.log(bufferedFile);
      await localforage.setItem(id, file);
      data.extension = file.type;
      data.type = file.type;
      data.signedUrl = undefined;
      if (data.extension.includes('jpeg') || data.extension.includes('jpg'))
        data.extension = 'jpeg';
      if (data.extension.includes('pdf')) data.extension = 'pdf';
      setCurrentlyUpdatingFile(id);
    }

    // await new Promise((resolve, reject) => {
    //   if (file) {
    //     data.base64File = URL.createObjectURL(file);
    //     setCurrentlyUpdatingFile(id);
    //     const fileReader = new FileReader();
    //     fileReader.readAsDataURL(file);
    //     fileReader.onloadend = () => {
    //       data.base64File = fileReader.result as string;
    //       resolve(undefined);
    //     };
    //   } else {
    //     resolve(undefined);
    //   }
    // });

    dispatch(
      actions.addOrUpdateImage({
        id,
        data,
        collection: splittedPath.join('/'),
      }),
    );
  };

  const getImageUrl = (url: string) => {
    try {
      return new URL(url).toString();
    } catch (e) {
      return new URL(`${API_URL}${url}`).toString();
    }
  };

  const getImagePreview = async (image) => {
    let blob;
    if (image.signedUrl) {
    }

    blob = (await localforage.getItem(image.id)) || blob;

    return blob;
  };

  React.useEffect(() => {
    if (!isCallingApi) setCurrentlyUpdatingFile(undefined);
  }, [setCurrentlyUpdatingFile, isCallingApi]);

  return (
    <Box position="relative">
      {collections.length > 0 && (
        <>
          {/* <ImageDialog
            maxWidth="lg"
            open={!!fullscreenImageUrl}
            onClose={() => setFullscreenImage(undefined)}
          >
            {fullscreenImageUrl &&
              getFileExtension(fullscreenImageUrl) !== 'pdf' && (
                <Fab
                  onClick={() => setFullscreenImage(undefined)}
                  children={<Close />}
                  size="small"
                />
              )}
            {fullscreenImageUrl &&
            getFileExtension(fullscreenImageUrl) === 'pdf' ? (
              <iframe
                title="pdf-viewer"
                src={fullscreenImageUrl}
                style={{ height: '100%', width: '700px', maxWidth: '100%' }}
                frameBorder="0"
              ></iframe>
            ) : (
              <img src={fullscreenImageUrl} alt="fullscreen-icon" />
            )}
          </ImageDialog> */}

          <StyledDrawer
            variant="persistent"
            anchor="right"
            open={isOpen}
            className={`${isOpen && 'is-open'}`}
          >
            <DocumentList>
              {collections.map(({ path, name }, galleryIndex) => (
                <StyledAccordion
                  square
                  key={name}
                  className={collections.length > 1 ? '' : 'unique'}
                  expanded={
                    collections.length === 1 || expandedGallery === path
                  }
                  onChange={() =>
                    setExpandedGallery((currentPath) =>
                      path === currentPath ? '' : path,
                    )
                  }
                >
                  {collections.length > 1 && (
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{name}</Typography>
                    </AccordionSummary>
                  )}
                  <AccordionDetails>
                    <ImageList cols={1}>
                      {images[path]?.map((image, i) => (
                        <ImageListItem key={image.name}>
                          <FilePreview
                            file={image}
                            onFullscreen={() => setFullscreenFile(image.id)}
                            onMinimize={() => setFullscreenFile(undefined)}
                            fullscreen={fullscreenFileId === image.id}
                          />
                          {/* {failingImages.includes(image.id) ? (
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Ic_attach_file_48px.svg/1200px-Ic_attach_file_48px.svg.png"
                              alt={image.name}
                            />
                          ) : (
                            <img
                              src={
                                image.base64File || getImageUrl(image.signedUrl)
                              }
                              alt={image.name}
                              onError={() =>
                                setFailingImages((current) => [
                                  ...current,
                                  image.id,
                                ])
                              }
                            />
                          )} */}

                          <ItemOverlay
                            canEdit={canEdit}
                            title={image.name}
                            onDelete={() => {
                              if (
                                isSuperadmin ||
                                isAdmin ||
                                (isBasic &&
                                  currentUser.id ===
                                    items.technicalVisit.technician)
                              )
                                handleDelete(image.id, path, image.name, name);
                            }}
                            onUpdate={(data) => {
                              if (
                                isSuperadmin ||
                                isAdmin ||
                                (isBasic &&
                                  currentUser.id ===
                                    items.technicalVisit.technician)
                              )
                                handleUpdate(image.id, data, image.path, name);
                            }}
                            onFullscreen={() => setFullscreenFile(image.id)}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </DocumentList>
          </StyledDrawer>

          <GalleryBtn
            onClick={() => setOpen(!isOpen)}
            Icon={isOpen ? CloseIcon : GalleryIcon}
            shifted={isOpen}
          />
        </>
      )}
    </Box>
  );
};

const StyledAccordion = styled(Accordion)`
  &.unique {
    box-shadow: none;
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
`;

const DocumentList = styled(Box)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const DocumentBox = styled(Box)`
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
`;

const StyledDrawer = styled(Drawer)`
  &.is-open {
    width: 250px;
  }

  .MuiDrawer-paper {
    width: 250px;
    overflow: hidden;
  }
`;

const GalleryBtn = ({ onClick, Icon, shifted }) => (
  <Tooltip title="Gallerie">
    <GalleryBtnFab
      color="primary"
      onClick={onClick}
      style={{
        right: shifted ? '250px' : '40px',
      }}
    >
      <Icon />
    </GalleryBtnFab>
  </Tooltip>
);

const GalleryBtnFab = styled(Fab)`
  &.MuiFab-root {
    position: fixed;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 1200;
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1);
  }
`;

interface ItemOverlayProps {
  title: string;
  canEdit: boolean;
  onDelete: () => void;
  onUpdate: (data: UpdateHandlerParams) => void;
  onFullscreen: () => void;
}
const ItemOverlay = ({
  canEdit,
  title,
  onDelete,
  onUpdate,
  onFullscreen,
}: ItemOverlayProps) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [isInputShown, toggleInput] = React.useState(false);
  const [currentTitle, setCurrentTitle] = React.useState(
    decodeURIComponent(title),
  );

  const handleUpdate = async (e) => {
    if (e.type === 'click' || (e.type === 'keypress' && e.code === 'Enter')) {
      toggleInput(false);
      onUpdate({ title: currentTitle });
    } else if (e.type === 'change') {
      const { files } = e.target;
      if (files && files.length && files[0]) {
        let file = files[0];
        if (file.type.includes('image')) {
          file = await compress(file, {
            quality: 0.7,
            type: EImageType.JPEG,
          });
          file = await compressAccurately(file, 800);
        }

        onUpdate({ file, title: currentTitle });
      }
    }
  };

  return (
    <StyledOverlay>
      <Box className="action-buttons">
        <IconButton size="small" color="primary" onClick={onFullscreen}>
          <Fullscreen />
        </IconButton>

        {canEdit && (
          <>
            <IconButton
              size="small"
              color="primary"
              onClick={() => toggleInput(!isInputShown)}
            >
              {isInputShown ? <Close /> : <Edit />}
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => inputFile?.current?.click()}
            >
              <CameraAlt />
              <input
                type="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={handleUpdate}
              />
            </IconButton>
            <IconButton size="small" color="primary" onClick={onDelete}>
              <Delete />
            </IconButton>
          </>
        )}
      </Box>

      <TogglableTextField
        inputRef={(input) => isInputShown && input && input.focus()}
        label="Nom du fichier"
        size="small"
        variant="filled"
        value={currentTitle}
        className={isInputShown ? 'displayed-input' : ''}
        onChange={(e) => setCurrentTitle(e.target.value)}
        onKeyPress={handleUpdate}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleUpdate} children={<Save />} />
            </InputAdornment>
          ),
        }}
      />

      {!isInputShown && (
        <Typography
          className="typography"
          children={decodeURIComponent(title)}
        />
      )}
    </StyledOverlay>
  );
};

const StyledOverlay = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .typography {
    color: white;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 14px;
  }
  .action-buttons {
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 80%
    );
  }
  &:hover .action-buttons {
    visibility: visible;
  }
`;

const TogglableTextField = styled(TextField)`
  position: absolute !important;
  bottom: 100%;
  transition: bottom 200ms ease-in-out;
  &.displayed-input {
    bottom: 0;
  }
  .MuiFilledInput-root {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
  .MuiFilledInput-adornedEnd {
    padding-right: 0;
  }
`;
