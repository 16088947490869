import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  ResourceDialogParams,
  Resources,
  FetchItemsParams,
  AddOrUpdateItemParams,
  SetItemsParams,
} from './types';
import { Image } from '../TechnicalVisitPage/types';
import { ListInterface } from 'app/types';

// The initial state of the SettingsPage container
export const initialState: ContainerState = {
  users: [],
  lists: [],
  sublists: [],
  unifilaires: [],
  resourceDialogParams: {},
};

const settingsPageSlice = createSlice({
  name: 'settingsPage',
  initialState,
  reducers: {
    resetResources(state) {
      Object.values(Resources).forEach((resource) => (state[resource] = []));
    },
    fetchItems(state, action: PayloadAction<FetchItemsParams>) {},
    setItems<T>(state, action: PayloadAction<SetItemsParams<T>>) {
      const { resource, items } = action.payload;
      state[resource] = items as any;
    },
    fetchUnifilaireFiles(state) {},
    addOrUpdateItem<T>(
      state,
      action: PayloadAction<AddOrUpdateItemParams<T>>,
    ) {},
    addUnifilaire(
      state,
      action: PayloadAction<{ data: any; list: Partial<ListInterface> }>,
    ) {},
    updateUnifilaire(
      state,
      action: PayloadAction<{ data: any; list?: Partial<ListInterface> }>,
    ) {},
    setResourceDialogParams(
      state,
      action: PayloadAction<ResourceDialogParams>,
    ) {
      state.resourceDialogParams = action.payload;
    },
    resetResourceDialogParams(
      state,
      action: PayloadAction<[Resources] | undefined>,
    ) {
      state.resourceDialogParams = {};
      action?.payload?.forEach((resource) => (state[resource] = []));
    },
  },
});

export const { actions, reducer, name: sliceKey } = settingsPageSlice;
