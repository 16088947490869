import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';

interface Props {
  open?: boolean;
  handleClose: (txt) => void;
}

export default function DialogComment({ open = false, handleClose }: Props) {
  const { t } = useTranslation();
  const [comment, setComment] = React.useState('');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {t(translations.TechnicalVisitForms.dialogComment.title)}
      </DialogTitle>
      <DialogContent>
        <TextField
          name="comment"
          variant="filled"
          label={t(translations.TechnicalVisitForms.dialogComment.subTitle)}
          rows={6}
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(null)}
          variant="outlined"
          color="primary"
        >
          {t(translations.ConfirmDialog.cancel)}
        </Button>
        <Button
          onClick={() => handleClose(comment)}
          variant="contained"
          color="primary"
          disabled={!comment}
        >
          {t(translations.ConfirmDialog.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
