import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { appDataSelector } from 'app/selectors';
import { ResourceDialogParams } from '../types';
import { actions } from '../slice';

import { Formik, Form } from 'formik';
import { Typography, Box } from '@material-ui/core';
import { FloatingSubmitBtn } from 'app/components/FloatingSubmitBtn';
import { DialogContext } from './Context';
import { StandardDialog } from 'app/components/StandardDialog';

export function AddOrUpdateDialog({
  title,
  resource,
  refreshResources,
  item,
  readOnly = false,
}: ResourceDialogParams) {
  const dispatch = useDispatch();
  const { isCallingApi } = useSelector(appDataSelector);
  const onClose = () =>
    dispatch(actions.resetResourceDialogParams(refreshResources));

  return (
    <DialogContext.Provider {...{ readOnly, item }}>
      {item && resource && (
        <DialogContext.Consumer>
          {({ validationSchema, resourceForms }) => (
            <StandardDialog isOpen={true} onClose={onClose}>
              <Typography variant="h6">{title}</Typography>

              <Formik
                enableReinitialize
                initialValues={item}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  dispatch(
                    actions.addOrUpdateItem({
                      resource,
                      refreshResources,
                      item: values,
                    }),
                  );
                  !_.isNumber(values.id) && onClose();
                }}
              >
                <Box display="flex" alignItems="center" component={Form}>
                  <Box>{resourceForms[resource]}</Box>

                  {!readOnly && (
                    <FloatingSubmitBtn
                      dataTestId="details-form-submit"
                      disabled={isCallingApi}
                    />
                  )}
                </Box>
              </Formik>
            </StandardDialog>
          )}
        </DialogContext.Consumer>
      )}
    </DialogContext.Provider>
  );
}
