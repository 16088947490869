import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { format, subDays, startOfToday } from 'date-fns';

import { appDataSelector } from 'app/selectors';
import { reducer, sliceKey } from './slice';
import { dashboardPageSaga } from './saga';

import { Box, Typography } from '@material-ui/core';
import { Refresh, Map, TableChart } from '@material-ui/icons';

import { AnimatableButton } from 'app/components/AnimatableButton';
import { TechnicalVisit } from './TechnicalVisit';
import { EventMap } from './EventMap';
import { actions } from './slice';
import { actions as appActions } from 'app/slice';
import { NetworkContext } from 'providers/NetworkProvider';
import { States } from 'types';

export function DashboardPage() {
  const dispatch = useDispatch();
  // useInjectReducer({ key: sliceKey, reducer: reducer });
  // useInjectSaga({ key: sliceKey, saga: dashboardPageSaga });
  const { isCallingApi, currentUser, syncStatus } = useSelector(
    appDataSelector,
  );
  const [refreshAt, setRefreshAt] = React.useState(new Date());
  const [isMapView, toggleMapView] = React.useState(false);
  const { offline, online } = React.useContext(NetworkContext);

  const isNetworkOnline = React.useMemo(() => !offline && online, [
    offline,
    online,
  ]);

  React.useEffect(() => {
    if (isNetworkOnline) dispatch(appActions.startCaching());
  }, [dispatch, currentUser.id, isNetworkOnline]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={'stretch'}
        width={'fill-available'}
        height={'fill-available'}
        flexGrow={1}
        style={{
          margin: '2% 1%',
          transition: 'all 0.8s',
          position: 'relative',
        }}
        boxShadow={1}
      >
        {isNetworkOnline && (
          <BtnsGroup>
            {[
              {
                ariaLabel: 'changeView',
                rotate: isCallingApi,
                onClick: () => toggleMapView((old) => !old),
                disabled: isCallingApi,
                children: isMapView ? <TableChart /> : <Map />,
              },
              {
                ariaLabel: 'refresh',
                rotate: isCallingApi,
                onClick: () => setRefreshAt(new Date()),
                disabled: isCallingApi,
                children: <Refresh />,
              },
            ].map((button) => (
              <AnimatableButton {...button} />
            ))}
          </BtnsGroup>
        )}

        {isMapView ? (
          <EventMap />
        ) : (
          <TechnicalVisit
            {...{
              refreshAt,
            }}
          />
        )}
      </Box>
    </>
  );
}

const BtnsGroup = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
`;
