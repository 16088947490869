/**
 *
 * AuthProvider
 *
 */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

interface Props {
  children?: any;
}

const useQuery = () => new URLSearchParams(useLocation().search);

export const AuthProvider = ({ children }: Props) => {
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN!;
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const audience: string = process.env.REACT_APP_AUTH0_AUDIENCE!;

  const query = useQuery();

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      skipRedirectCallback={query.get('error') ? true : false}
      audience={audience}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};
