/**
 *
 * MultipleSelect
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { translations } from 'locales/i18n';
import { MenuOption } from '../types';

import {
  OutlinedTextFieldProps,
  TextField,
  Checkbox,
  ListItemText,
  ListItemIcon,
  MenuItem as MuiMenuItem,
  IconButton,
} from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';

interface Props extends OutlinedTextFieldProps {
  options: MenuOption[];
  value: React.ReactText[];
  canClear?: boolean;
  ToolbarComponent?: React.ReactElement;
  onClose?: (event: React.ReactText[]) => void;
  renderValue?: React.ReactNode;
}

const ALL = 'all';

export const MultipleSelect = ({
  options,
  variant,
  value: values = [],
  onChange,
  onClose,
  canClear = true,
  ToolbarComponent,
  renderValue,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(values);
  const handleChange = (values) => {
    const newSelection = values.includes(ALL)
      ? selected.length === options.length
        ? []
        : options.map(({ value }) => value)
      : values;

    setSelected(newSelection);

    values = newSelection;
    onChange && onChange(values);
  };

  const clear = () => {
    setSelected([]);
    onChange && onChange([] as any);
  };

  return (
    <>
      <TextField
        select
        value={selected}
        SelectProps={{
          onClose: () => {
            onClose && onClose(selected);
            setOpen(false);
          },
          open: open,
          multiple: true,
          MenuProps: { autoFocus: false },
          IconComponent: () => (
            <IconButton>
              <ExpandMore onClick={() => setOpen(true)} />
            </IconButton>
          ),
          renderValue: () =>
            renderValue ||
            _.map(
              _.filter(options, ({ value }) => selected.includes(value)),
              ({ displayedValue, value }) => displayedValue || value,
            ).join(', '),
        }}
        onChange={(event) => handleChange(event.target.value)}
        {...props}
      >
        <MenuItem key={`menu-item-all`} value={ALL}>
          <ListItemIcon>
            <Checkbox
              checked={selected.length === options.length}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={
              selected.length === options.length
                ? `${t(translations.MultipleSelect.unSelectAll)}`
                : `${t(translations.MultipleSelect.selectAll)}`
            }
          />
        </MenuItem>
        {options.map(({ value, displayedValue }) => (
          <MenuItem key={`menu-item-${value}`} value={value}>
            <ListItemIcon>
              <Checkbox checked={selected.includes(value)} />
            </ListItemIcon>
            <ListItemText primary={displayedValue} />
          </MenuItem>
        ))}
        {ToolbarComponent}
      </TextField>

      {selected.length > 0 && canClear && (
        <IconButton size="small" onClick={clear}>
          <Clear fontSize="small" color="error" />
        </IconButton>
      )}
    </>
  );
};

const MenuItem = styled(MuiMenuItem)`
  &.looks-disabled {
    opacity: 0.5;
  }
`;
