import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

interface FullAddress {
  address?: string;
  city?: string;
  zip?: string;
  coordinates?: string;
}

interface Props {
  defaultValue: string;
  label: string;
  manualAddressForm?: React.ReactElement;
  onAddress: (address: FullAddress) => void;
  disabled?: boolean;
}

export const AddressAutocomplete = ({
  defaultValue,
  label,
  manualAddressForm,
  disabled = false,
  onAddress,
}: Props) => {
  const [showAutocomplete, toogleAutocompleteVisibility] = React.useState(true);
  const [
    adressSearchInput,
    setAdressSearchInput,
  ] = React.useState<google.maps.places.Autocomplete>();
  const [autocompleteValue, setAutocompleteValue] = React.useState(
    defaultValue,
  );

  const onPlaceChanged = React.useCallback(() => {
    if (adressSearchInput) {
      const place = adressSearchInput.getPlace();
      let streetNumber = '';
      const fullAddress: FullAddress = {};
      if (place) {
        if (place.geometry?.location)
          fullAddress.coordinates = `${place.geometry?.location?.lat()},${place.geometry?.location?.lng()}`;

        place.address_components?.forEach(({ long_name, types: [type] }) => {
          switch (type) {
            case 'street_number': {
              streetNumber = long_name;
              break;
            }
            case 'route': {
              fullAddress.address = `${streetNumber} ${long_name}`;
              break;
            }
            case 'postal_code': {
              fullAddress.zip = `${long_name}`;
              break;
            }
            case 'locality': {
              fullAddress.city = `${long_name}`;
              break;
            }
          }
        });
        onAddress(fullAddress);
      }
    }
  }, [onAddress, adressSearchInput]);

  React.useEffect(() => {
    setAutocompleteValue(defaultValue);
  }, [setAutocompleteValue, defaultValue]);

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch">
      {showAutocomplete ? (
        <Box padding="10px">
          <Autocomplete
            onLoad={(input) => setAdressSearchInput(input)}
            onPlaceChanged={onPlaceChanged}
            restrictions={{ country: 'fr' }}
            options={{
              fields: ['address_components', 'geometry', 'formatted_address'],
              types: ['address'],
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label={label}
              value={autocompleteValue}
              onChange={(el) => setAutocompleteValue(el.target.value)}
              disabled={disabled}
            />
          </Autocomplete>
          {!disabled && (
            <Link
              component="button"
              variant="body2"
              style={{ textAlign: 'right' }}
              onClick={(e) => {
                e.preventDefault();
                toogleAutocompleteVisibility(false);
              }}
            >
              Je ne trouve pas l'adresse ?
            </Link>
          )}
        </Box>
      ) : (
        manualAddressForm
      )}
    </Box>
  );
};
