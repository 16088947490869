import {
  CustomerInterface,
  FetchTechnicalVisitsParams,
  ListInterface,
  TechnicalVisitInterface,
} from 'app/types';
import {
  ElectricityInterface,
  RoofFrameInterface,
  GeneratorInterface,
  RadiatorInterface,
  EquipmentInterface,
  AddOrUpdateImageParams,
  DocumentDtInterface,
  SelfConsumptionInterface,
  ExportImagesParams,
  Image,
  addFile,
} from 'app/containers/TechnicalVisitPage/types';
import axios from 'axios';
import { UserInterface, UserQuery } from 'types';
import buildFormData from './build-formdata';
import fetchAdapter from '@vespaiach/axios-fetch-adapter';
import apiUrlBuilder from './api-url-builder';

export const API_URL = process.env.REACT_APP_API_GATEWAY_HOST;

// let kyApi = ky.create({
//   prefixUrl: API_URL,
// });
// let gateway = kyApi;

const gateway = axios.create({
  baseURL: API_URL,
  adapter: fetchAdapter,
});

export const setConfig = (
  sessionToken: string,
  onStart: (url: string) => any,
  onEnd: (
    url: string,
    status: 'error' | 'success',
    showMessage: boolean,
  ) => any,
) => {
  // gateway = kyApi.extend({
  //   headers: {
  //     Authorization: `Bearer ${sessionToken}`,
  //   },
  //   hooks: {
  //     beforeRequest: [
  //       (req, options) => {
  //         onStart(req.url);
  //       },
  //     ],
  //     afterResponse: [
  //       (req, options, res) => {
  //         if (res.status < 300) {
  //           onEnd(req.url, 'success', req.method !== 'get');
  //         } else {
  //           onEnd(req.url, 'error', true);
  //         }
  //         return res;
  //       },
  //     ],
  //     beforeError: [
  //       (err) => {
  //         onEnd(err.request.url, 'error', true);
  //         return err;
  //       },
  //     ],
  //   },
  // });

  gateway.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`;

  gateway.interceptors.request.use(
    (config) => {
      onStart(config.url as string);
      return config;
    },
    (error) => onEnd(error.config.url as string, 'error', true),
  );

  gateway.interceptors.response.use(
    (response) => {
      if (response?.config) {
        onEnd(
          response.config.url as string,
          'success',
          response.config.method !== 'get',
        );
        return response;
      }
      throw new Error(`${response?.status}`);
    },
    (error) => {
      // console.error(error);
      onEnd(error?.config?.url as string, 'error', true);
      throw new Error(error?.response?.status || error.message);
    },
  );
};

export const api = {
  config: async () => {
    const { data } = await gateway.get('config');
    return data;
  },
  users: {
    one: async (id: string): Promise<UserInterface> => {
      const { data } = await gateway.get(`users/${id}`);
      return data;
    },
    add: async (user: Partial<UserInterface>): Promise<UserInterface> => {
      const { data } = await gateway.post(`users`, user);
      return data;
    },
    update: async ({
      id,
      ...user
    }: Partial<UserInterface>): Promise<UserInterface> => {
      const { data } = await gateway.patch(`users/${id}`, user);
      return data;
    },
    all: async (query?: UserQuery): Promise<UserInterface[]> => {
      const { data } = await gateway.get(`users`, {
        params: query,
      });
      return data;
    },
  },
  lists: {
    search: async (body): Promise<ListInterface[]> => {
      const { data } = await gateway.post(`lists/search`, body);
      return data;
    },
    one: async (id: string): Promise<ListInterface> => {
      const { data } = await gateway.get(`lists/${id}`);
      return data;
    },
    delete: async (id: number) => {
      await gateway.delete(`list/${id}`);
    },
    add: async (list: Partial<ListInterface>): Promise<ListInterface> => {
      const { data } = await gateway.post(`lists`, list);
      return data;
    },
    update: async ({
      id,
      ...list
    }: Partial<ListInterface>): Promise<ListInterface> => {
      const { data } = await gateway.patch(`list/${id}`, list);
      return data;
    },

    updateTranslation: async ({
      id,
      name,
    }: Partial<ListInterface>): Promise<ListInterface> => {
      const { data } = await gateway.put(`lists/${id}/translations`, { name });
      return data;
    },
    all: async (): Promise<ListInterface[]> => {
      const { data } = await gateway.get(`lists`);
      return data;
    },
  },
  technicalVisit: {
    searchTechnicalVisit: async (
      body,
      params,
    ): Promise<TechnicalVisitInterface[]> => {
      const { data } = await gateway.post(`technical-visits/search`, body);
      return data;
    },
    generate: async ({ id, ...body }): Promise<TechnicalVisitInterface> => {
      const { data } = await gateway.post(
        `technical-visits/generate/${id}`,
        body,
      );
      try {
        window.open(new URL(data.signedUrl).toString(), '_blank');
      } catch (e) {
        window.open(
          new URL(`${API_URL}${data.signedUrl}`).toString(),
          '_blank',
        );
      }
      return data;
    },
    duplicate: async ({ id, ...body }): Promise<TechnicalVisitInterface> => {
      const { data } = await gateway.post(
        `technical-visits/duplicate/${id}`,
        body,
      );
      return data;
    },
    one: async (id: string) => {
      const { data } = await gateway.get<TechnicalVisitInterface>(
        `technical-visits/${id}`,
      );
      return data;
    },
    add: async (
      technicalVisit: Partial<TechnicalVisitInterface>,
    ): Promise<TechnicalVisitInterface> => {
      const { data } = await gateway.post(`technical-visits`, technicalVisit);
      return data;
    },
    update: async ({
      id,
      ...technicalVisit
    }: Partial<TechnicalVisitInterface>): Promise<TechnicalVisitInterface> => {
      const { data } = await gateway.patch(
        `technical-visits/${id}`,
        technicalVisit,
      );
      return data;
    },
    all: async (
      params: FetchTechnicalVisitsParams,
    ): Promise<TechnicalVisitInterface[]> => {
      const { data } = await gateway.get(`technical-visits`, {
        params,
      });
      return data;
    },

    delete: async (id: Partial<string>): Promise<void> => {
      await gateway.delete(`technical-visits/${id}`);
      return;
    },
  },
  customer: {
    one: async (id: string): Promise<CustomerInterface> => {
      const { data } = await gateway.get(`customers/${id}`);
      return data;
    },
    add: async (
      customer: Partial<CustomerInterface>,
    ): Promise<CustomerInterface> => {
      const { data } = await gateway.post(`customers`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<RoofFrameInterface>): Promise<RoofFrameInterface> => {
      const { data } = await gateway.patch(`customers/${id}`, customer);
      return data;
    },
    all: async (): Promise<RoofFrameInterface[]> => {
      const { data } = await gateway.get(`customers`);
      return data;
    },
  },
  roofFrame: {
    one: async (id: string): Promise<RoofFrameInterface> => {
      const { data } = await gateway.get(`roof-frames/${id}`);
      return data;
    },
    add: async (
      customer: Partial<RoofFrameInterface>,
    ): Promise<RoofFrameInterface> => {
      const { data } = await gateway.post(`roof-frames`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<ElectricityInterface>): Promise<ElectricityInterface> => {
      const { data } = await gateway.patch(`roof-frames/${id}`, customer);
      return data;
    },
    all: async (): Promise<ElectricityInterface[]> => {
      const { data } = await gateway.get(`roof-frames`);
      return data;
    },
    delete: async (id: string, tags: object): Promise<RoofFrameInterface> => {
      const { data } = await gateway.delete(`roof-frames/${id}`, {
        data: tags,
      });
      return data;
    },
  },
  electricity: {
    one: async (id: string): Promise<ElectricityInterface> => {
      const { data } = await gateway.get(`electricities/${id}`);
      return data;
    },
    add: async (
      customer: Partial<ElectricityInterface>,
    ): Promise<ElectricityInterface> => {
      const { data } = await gateway.post(`electricities`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<ElectricityInterface>): Promise<ElectricityInterface> => {
      const { data } = await gateway.patch(`electricities/${id}`, customer);
      return data;
    },
    all: async (): Promise<ElectricityInterface[]> => {
      const { data } = await gateway.get(`electricities`);
      return data;
    },
  },
  generator: {
    one: async (id: string): Promise<GeneratorInterface> => {
      const { data } = await gateway.get(`generators/${id}`);
      return data;
    },
    add: async (
      customer: Partial<GeneratorInterface>,
    ): Promise<GeneratorInterface> => {
      const { data } = await gateway.post(`generators`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<GeneratorInterface>): Promise<GeneratorInterface> => {
      const { data } = await gateway.patch(`generators/${id}`, customer);
      return data;
    },
    all: async (): Promise<GeneratorInterface[]> => {
      const { data } = await gateway.get(`generators`);
      return data;
    },
  },
  radiator: {
    one: async (id: string): Promise<RadiatorInterface> => {
      const { data } = await gateway.get(`radiators/${id}`);
      return data;
    },
    add: async (
      customer: Partial<RadiatorInterface>,
    ): Promise<RadiatorInterface> => {
      const { data } = await gateway.post(`radiators`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<RadiatorInterface>): Promise<RadiatorInterface> => {
      const { data } = await gateway.patch(`radiators/${id}`, customer);
      return data;
    },
    all: async (): Promise<RadiatorInterface[]> => {
      const { data } = await gateway.get(`radiators`);
      return data;
    },
    delete: async (id: string, tags: object): Promise<RadiatorInterface> => {
      const { data } = await gateway.delete(`radiators/${id}`, {
        data: tags,
      });
      return data;
    },
  },
  selfConsumption: {
    one: async (id: string): Promise<SelfConsumptionInterface> => {
      const { data } = await gateway.get(`self-consumptions/${id}`);
      return data;
    },
    add: async (
      customer: Partial<SelfConsumptionInterface>,
    ): Promise<RadiatorInterface> => {
      const { data } = await gateway.post(`self-consumptions`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<SelfConsumptionInterface>): Promise<SelfConsumptionInterface> => {
      const { data } = await gateway.patch(`self-consumptions/${id}`, customer);
      return data;
    },
    all: async (): Promise<SelfConsumptionInterface[]> => {
      const { data } = await gateway.get(`self-consumptions`);
      return data;
    },
    delete: async (
      id: string,
      tags: object,
    ): Promise<SelfConsumptionInterface> => {
      const { data } = await gateway.delete(`self-consumptions/${id}`, {
        data: tags,
      });
      return data;
    },
  },
  equipmentHandles: {
    one: async (id: string): Promise<EquipmentInterface> => {
      const { data } = await gateway.get(`equipment-handles/${id}`);
      return data;
    },
    add: async (
      customer: Partial<EquipmentInterface>,
    ): Promise<RadiatorInterface> => {
      const { data } = await gateway.post(`equipment-handles`, customer);
      return data;
    },
    update: async ({
      id,
      ...customer
    }: Partial<EquipmentInterface>): Promise<EquipmentInterface> => {
      const { data } = await gateway.patch(`equipment-handles/${id}`, customer);
      return data;
    },
    all: async (): Promise<EquipmentInterface[]> => {
      const { data } = await gateway.get(`equipment-handles`);
      return data;
    },
    delete: async (id: string, tags?: object): Promise<EquipmentInterface> => {
      const { data } = await gateway.delete(`equipment-handles/${id}`, {
        data: tags,
      });
      return data;
    },
  },
  files: {
    add: async (file) => {
      try {
        const formData = file.id ? await buildFormData(file) : file;
        const res = await gateway.post('files', formData);

        if (res.status === 200) return res.data;
        else return { status: res.status };
      } catch (error) {
        console.log(error);
      }
    },
    update: async (file: Partial<Image>) => {
      const formData = file.id ? await buildFormData(file) : file;

      const { data, status } = await gateway.patch(
        `files/${file.id || (formData as FormData).get('id')}`,
        formData,
      );
      return data;
    },
    delete: async (id: string, data) => {
      await gateway.delete(`files/${id}`, { data });
    },
    all: async (resources: string) => {
      const { data } = await gateway.get('files', {
        params: { resources },
      });
      return data;
    },
    export: async (body: ExportImagesParams) => {
      const { data } = await gateway.post('files/export', body);
      const link = document.createElement('a');
      link.href = apiUrlBuilder(API_URL!, data.signedUrl);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
  history: {
    all: async (tags: object) => {
      const { data } = await gateway.post('history/search', { tags });
      return data;
    },
  },

  documentDt: {
    one: async (id: string): Promise<DocumentDtInterface> => {
      const { data } = await gateway.get(`document-dts/${id}`);
      return data;
    },
    add: async (
      documentDt: Partial<DocumentDtInterface>,
    ): Promise<RadiatorInterface> => {
      const { data } = await gateway.post(`document-dts`, documentDt);
      return data;
    },
    update: async ({
      id,
      ...documentDt
    }: Partial<DocumentDtInterface>): Promise<DocumentDtInterface> => {
      const { data } = await gateway.patch(`document-dts/${id}`, documentDt);
      return data;
    },
    all: async (): Promise<DocumentDtInterface[]> => {
      const { data } = await gateway.get(`document-dts`);
      return data;
    },
    delete: async (id: string, tags?: object): Promise<EquipmentInterface> => {
      const { data } = await gateway.delete(`document-dts/${id}`, {
        data: tags,
      });
      return data;
    },
  },
};
