import React from 'react';
import { IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { Fab, Tooltip, SvgIcon } from '@material-ui/core';
import { CameraAlt as CameraIcon } from '@material-ui/icons';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import { compress, compressAccurately, EImageType } from 'image-conversion';

interface FileParams {
  label: string;
  key: string;
  path: string;
  required?: boolean;
}

interface CameraProps {
  onSelect: (file: File, fileParams?: Omit<FileParams, 'required'>) => void;
  collectionPath: string;
  disabled?: boolean;
  files?: FileParams[];
  accept?: string;
  uploaded?: (key: string, path: string) => boolean;
  icon?: typeof SvgIcon;
}

export const CameraSelect = ({
  onSelect,
  collectionPath,
  disabled,
  files = [],
  accept = 'image/*',
  uploaded = () => false,
  icon: Icon = CameraIcon,
}: CameraProps) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [fileParams, setFileParams] = React.useState<
    Omit<FileParams, 'required'>
  >();

  const onSelectClick = (params: Omit<FileParams, 'required'>) => {
    setFileParams(params);
    inputFile?.current?.click();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFile = async (e) => {
    const { files } = e.target;
    if (files && files.length && files[0]) {
      if (accept !== 'application/pdf') {
        let fileCompressed = await compress(files[0], {
          quality: 0.7,
          type: EImageType.JPEG,
        });
        fileCompressed = await compressAccurately(fileCompressed, 800);
        onSelect(fileCompressed as File, fileParams);
      } else {
        onSelect(files[0], fileParams);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip title={disabled ? "Sauvegardez avant d'ajouter une image" : ''}>
        <label htmlFor="upload-photo">
          <input
            type="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleFile}
            accept={accept}
          />
          <Fab
            onClick={handleClick}
            disabled={disabled}
            style={{ marginRight: '10px' }}
          >
            <Icon color="inherit" />
          </Fab>
        </label>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List aria-label="main mailbox folders">
          {files?.map(({ label, key, required = false }) => {
            const alreadyUploaded = uploaded(key, collectionPath);
            return (
              <ListItem
                button
                key={key}
                onClick={() =>
                  onSelectClick({ label, key, path: collectionPath })
                }
              >
                <IconButton edge="start">
                  {alreadyUploaded ? (
                    <CheckCircleRoundedIcon />
                  ) : (
                    <RadioButtonUncheckedRoundedIcon />
                  )}
                </IconButton>
                <Tooltip title={required ? 'Obligatoire' : ''}>
                  <ListItemText
                    style={{
                      color: alreadyUploaded ? 'grey' : 'inherit',
                      textDecoration: alreadyUploaded ? 'line-through' : 'none',
                    }}
                    primary={`${label}${required ? ' *' : ''}`}
                  />
                </Tooltip>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
