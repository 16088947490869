import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, cps, put, select, takeLatest } from 'redux-saga/effects';
import _, { forEach, merge } from 'lodash';
import jwtDecode from 'jwt-decode';

import { api } from 'utils/api';
import { actions } from 'app/slice';
import { actions as appActions } from 'app/slice';
import { States, UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import {
  AddOrUpdateItemParams,
  AddOrUpdateListParams,
  FetchTechnicalVisitsParams,
  Resources,
  SearchTechnicalVisitsParams,
} from 'app/types';
import { dashboardPageSelector } from 'app/containers/DashboardPage/selectors';
import { updateOrAddToCollection } from 'utils/transform';
import { technicalVisit } from 'app/containers/TechnicalVisitPage/initialStates';
import { subDays, startOfToday } from 'date-fns';

export function* getCacheableTechnicalVisitsIds() {
  const {
    technicalVisitsSyncablePaths,
    currentUser: { id },
  } = yield select(appDataSelector);

  //We need upcoming technical visits that need to be done
  const upcomingTechnicalVisits = yield call(api.technicalVisit.all, {
    technician: id,
    appointmentAt: [subDays(startOfToday(), 10)],
    status: States.PLANIFIED,
  });

  //Cacheable technical visits are all upcoming and syncable ones
  return _.compact(
    _.union(
      upcomingTechnicalVisits.map(({ technicalVisit: { id } }) => id),
      Object.keys(technicalVisitsSyncablePaths),
    ),
  );
}

export async function fetchImagesCollections(item) {
  const fetchedImagesCollections = {};
  const chunkedCollectionsNameFetching = _.flatten(
    Object.keys(item).map((key) => {
      const baseCollectionName = [
        item.technicalVisit.id,
        item[key]?.id && item[key].id !== item.technicalVisit.id
          ? item[key].id
          : key,
      ].join('/');

      if (!_.isArray(item[key])) return baseCollectionName;

      return item[key].map(({ id }) => [baseCollectionName, id].join('/'));
    }),
  ).reduce((acc, collectionName, idx) => {
    const chunkIdx = Math.floor(idx / 2);
    acc[chunkIdx] = [...(acc[chunkIdx] || []), collectionName];
    return acc;
  }, [] as any[][]);

  for (let collectionFetchingChunk of chunkedCollectionsNameFetching) {
    await Promise.all(
      collectionFetchingChunk.map(async (collectionName) => {
        fetchedImagesCollections[collectionName] = await api.files.all(
          collectionName,
        );
      }),
    );
  }

  return fetchedImagesCollections;
}
