import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import {
  Box,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { ElectricityInterface } from '../types';
import { useFormikContext } from 'formik';
import _ from 'lodash';

export const Form = () => {
  const {
    values,
    setFieldValue,
    submitForm,
  } = useFormikContext<ElectricityInterface>();
  const { t } = useTranslation();
  const { setValidationSchema, variant } = useStepperContext();
  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );

  const [refresh, setRefresh] = React.useState(false);
  const [listKeys] = React.useState([
    'power',
    'inside_or_outside',
    'subscription_type',
    'racking_power',
    'network_manager',
    'external_enclosure_type',
    'type_connection',
    'envoy',
    'ac_protection',
    'self_consumption_type',
  ]);

  React.useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  React.useEffect(() => {
    setRefresh(true);
    const options = {};
    // eslint-disable-next-line array-callback-return
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          supply: yup.number().nullable(),
          rackingPower: yup.number().nullable(),
          wallCrossing: yup.boolean().nullable().default(false),
          resumptionConnectionValue: yup.boolean().nullable().default(false),
          resumptionConnectionValueComment: yup.string().nullable(),
          slabCrossing: yup.boolean().nullable().default(false),
          counterLocation: yup.number().nullable(),
          circuitBreakerLocation: yup.number().nullable(),
          groundConnectionValue: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          pdlNumber: yup.string().nullable(),
          distanceCounterPanel: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          distanceDmiPanel: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          distanceDmiField: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          presenceId: yup.boolean().nullable().default(false),
          freeTerminalPanel: yup.boolean().nullable().default(false),
          subscriptionType: yup.number().nullable(),
          slackPeriods: yup.string().nullable(),
          furtherInformation: yup.string().nullable(),
          networkManager: yup.number().nullable(),
          connectionType: yup.number().nullable(),
          externalEnclosureType: yup.number().nullable(),
          selfConsumption: yup.boolean().default(false),
          totalResale: yup.boolean().default(false),
          inverterMarkResale: yup.string().nullable(),
          panelNbrResale: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          powerAlreadyInstalledResale: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          installationYearResale: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          connectionRequestNumberResale: yup.string().nullable(),
          maxInjectionPower: yup.boolean().nullable().default(false),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {!refresh && (
        <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Electricité
            </Typography>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              {!_.isEmpty(listsOptions['power']) && (
                <FormikInput
                  name="supply"
                  label={t(translations.ElectricityForms.labels.supply)}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions['power'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '3' }}
                  component={SimpleSelect}
                />
              )}
              {!_.isEmpty(listsOptions['racking_power']) && (
                <FormikInput
                  name="rackingPower"
                  label={t(translations.ElectricityForms.labels.rackingPower)}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    options: listsOptions['racking_power'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '3' }}
                  component={SimpleSelect}
                />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="groundConnectionValue"
                label={t(
                  translations.ElectricityForms.labels.groundConnectionValue,
                )}
                extraAttr={{
                  disabled: isSupplier,
                  ...variant,
                  onChange: (e) =>
                    setFieldValue(
                      'groundConnectionValue',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '1' }}
                component={TextField}
              />
              <FormikInput
                name="resumptionConnectionValue"
                label={t(
                  translations.ElectricityForms.labels
                    .resumptionConnectionValue,
                )}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['resumptionConnectionValue'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            {values['resumptionConnectionValue'] && (
              <Box>
                <FormikInput
                  name="resumptionConnectionValueComment"
                  label={t(
                    translations.ElectricityForms.labels
                      .resumptionConnectionValueComment,
                  )}
                  extraAttr={{
                    ...variant,
                    disabled: isSupplier,
                    rows: 2,
                    multiline: true,
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '6' }}
                  component={TextField}
                />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="wallCrossing"
                label={t(translations.ElectricityForms.labels.wallCrossing)}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['wallCrossing'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
              <FormikInput
                name="slabCrossing"
                label={t(translations.ElectricityForms.labels.slabCrossing)}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['slabCrossing'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="pdlNumber"
                label={t(translations.ElectricityForms.labels.pdlNumber)}
                extraAttr={{ ...variant, disabled: isSupplier }}
                containerStyle={{ flex: '3' }}
                component={TextField}
              />
              <FormikInput
                name="distanceCounterPanel"
                label={t(
                  translations.ElectricityForms.labels.distanceCounterPanel,
                )}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  onChange: (e) =>
                    setFieldValue(
                      'distanceCounterPanel',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '3' }}
                component={TextField}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="distanceDmiPanel"
                label={t(translations.ElectricityForms.labels.distanceDmiPanel)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  onChange: (e) =>
                    setFieldValue(
                      'distanceDmiPanel',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '3' }}
                component={TextField}
              />
              <FormikInput
                name="distanceDmiField"
                label={t(translations.ElectricityForms.labels.distanceDmiField)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  onChange: (e) =>
                    setFieldValue(
                      'distanceDmiField',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '3' }}
                component={TextField}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="presenceId"
                label={t(translations.ElectricityForms.labels.presenceId)}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['presenceId'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
              <FormikInput
                name="freeTerminalPanel"
                label={t(
                  translations.ElectricityForms.labels.freeTerminalPanel,
                )}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['freeTerminalPanel'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              {listsOptions['subscription_type'] && (
                <FormikInput
                  name="subscriptionType"
                  label={t(
                    translations.ElectricityForms.labels.subscriptionType,
                  )}
                  extraAttr={{
                    disabled: isSupplier,
                    ...variant,
                    options: listsOptions['subscription_type'] || [],
                    style: { width: '100%' },
                  }}
                  containerStyle={{ flex: '3' }}
                  component={SimpleSelect}
                />
              )}

              <FormikInput
                name="slackPeriods"
                label={t(translations.ElectricityForms.labels.slackPeriods)}
                extraAttr={{ ...variant, disabled: isSupplier }}
                containerStyle={{ flex: '3' }}
                component={TextField}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="maxInjectionPower"
                label={t(
                  translations.ElectricityForms.labels.maxInjectionPower,
                )}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['maxInjectionPower'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            <Divider variant="middle" />
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="selfConsumption"
                label={t(translations.ElectricityForms.labels.selfConsumption)}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['selfConsumption'],
                  control: <Switch size="small" />,
                  onClick: (e) => submitForm(),
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="totalResale"
                label={t(translations.ElectricityForms.labels.totalResale)}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['totalResale'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            {values['totalResale'] && (
              <>
                <Box
                  display="flex"
                  flexDirection={onSmallDevice ? 'column' : 'row'}
                >
                  <FormikInput
                    name="inverterMarkResale"
                    label={t(translations.ElectricityForms.labels.inverterMark)}
                    extraAttr={{
                      ...variant,
                      disabled: isSupplier,
                      style: { width: '100%' },
                    }}
                    containerStyle={{ flex: '2' }}
                    component={TextField}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection={onSmallDevice ? 'column' : 'row'}
                >
                  <FormikInput
                    name="panelNbrResale"
                    label={t(translations.ElectricityForms.labels.panelNbr)}
                    extraAttr={{
                      ...variant,
                      disabled: isSupplier,
                      style: { width: '100%' },
                    }}
                    containerStyle={{ flex: '1' }}
                    component={TextField}
                  />
                  <FormikInput
                    name="powerAlreadyInstalledResale"
                    label={t(
                      translations.ElectricityForms.labels
                        .powerAlreadyInstalled,
                    )}
                    extraAttr={{
                      ...variant,
                      disabled: isSupplier,
                      onChange: (e) =>
                        setFieldValue(
                          'powerAlreadyInstalledResale',
                          e.target.value
                            ? e.target.value.replaceAll(',', '.')
                            : null,
                        ),
                      style: { width: '100%' },
                    }}
                    containerStyle={{ flex: '2' }}
                    component={TextField}
                  />
                </Box>

                <Box
                  display="flex"
                  flexDirection={onSmallDevice ? 'column' : 'row'}
                >
                  <FormikInput
                    name="installationYearResale"
                    label={t(
                      translations.ElectricityForms.labels.installationYear,
                    )}
                    extraAttr={{
                      ...variant,
                      disabled: isSupplier,
                      style: { width: '100%' },
                    }}
                    containerStyle={{ flex: '1' }}
                    component={TextField}
                  />
                  <FormikInput
                    name="connectionRequestNumberResale"
                    label={t(
                      translations.ElectricityForms.labels
                        .connectionRequestNumber,
                    )}
                    extraAttr={{
                      ...variant,
                      disabled: isSupplier,
                      style: { width: '100%' },
                    }}
                    containerStyle={{ flex: '1' }}
                    component={TextField}
                  />
                </Box>
              </>
            )}
          </Box>

          <Box>
            <Typography variant="h4" gutterBottom>
              Raccordement
            </Typography>

            {listsOptions['network_manager'] && (
              <FormikInput
                name="networkManager"
                label={t(translations.ElectricityForms.labels.networkManager)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['network_manager'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            )}

            {listsOptions['type_connection'] && (
              <FormikInput
                name="connectionType"
                label={t(translations.ElectricityForms.labels.connectionType)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['type_connection'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            )}

            {listsOptions['external_enclosure_type'] && (
              <FormikInput
                name="externalEnclosureType"
                label={t(
                  translations.ElectricityForms.labels.externalEnclosureType,
                )}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['external_enclosure_type'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            )}

            {listsOptions['inside_or_outside'] && (
              <FormikInput
                name="circuitBreakerLocation"
                label={t(
                  translations.ElectricityForms.labels.circuitBreakerLocation,
                )}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['inside_or_outside'],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            )}

            {listsOptions['inside_or_outside'] && (
              <FormikInput
                name="counterLocation"
                label={t(translations.ElectricityForms.labels.counterLocation)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['inside_or_outside'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            )}
          </Box>
          <Box>
            <FormikInput
              name="furtherInformation"
              label={t(translations.RoofFrameForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 15,
                multiline: true,
                style: { width: '100%' },
              }}
              containerStyle={{ flex: '6' }}
              component={TextField}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
