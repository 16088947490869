/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';
import { HelmetProvider } from 'react-helmet-async';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import frLocale from 'date-fns/locale/fr';
import { configureAppStore } from 'store/configureStore';
import { GlobalStyle } from 'styles/global-styles';

import { GoogleMapsProvider } from 'providers/GoogleMapsProvider';
import { AuthProvider } from 'providers/Auth0Provider';
import { NetworkProvider } from 'providers/NetworkProvider';
import reportWebVitals from './reportWebVitals';
import { register as registerServiceWorker } from './serviceWorkerRegistration';
import { PersistGate } from 'redux-persist/integration/react';

// Initialize languages
import './locales/i18n';

import App from './app';
import { ConfigProvider } from 'providers/ConfigProvider';
// import { Store } from '@reduxjs/toolkit';

// Auth provider to manage users sessions

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const { store, persistor } = configureAppStore();

interface Props {
  RenderedApp: typeof App;
  // store: Store;
}

const ConnectedApp = ({ RenderedApp }: Props) => (
  <BrowserRouter>
    <NetworkProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <HelmetProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <GoogleMapsProvider>
                  <React.StrictMode>
                    <GlobalStyle />
                    <ConfigProvider>
                      <RenderedApp />
                    </ConfigProvider>
                  </React.StrictMode>
                </GoogleMapsProvider>
              </MuiPickersUtilsProvider>
            </HelmetProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </NetworkProvider>
  </BrowserRouter>
);

// const render = (RenderedApp: typeof App) => {
//   configureAppStore((store) => {
//     ReactDOM.render(
//       <ConnectedApp RenderedApp={RenderedApp} store={store} />,
//       MOUNT_NODE,
//     );
//   });
// };

const render = (RenderedApp: typeof App) =>
  ReactDOM.render(<ConnectedApp RenderedApp={RenderedApp} />, MOUNT_NODE);

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    // const App = require('./app').App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();
reportWebVitals();
