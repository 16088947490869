import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { FormikInput } from 'app/components/FormikInput';
import { ElectricityInterface } from '../types';
import { useFormikContext } from 'formik';

export const Form = () => {
  const { values } = useFormikContext<ElectricityInterface>();
  const { t } = useTranslation();
  const { setValidationSchema, variant } = useStepperContext();
  const { onSmallDevice, lists, isSupplier } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );
  const [listKeys] = React.useState(['heating_subtype', 'heating_type']);
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  React.useEffect(() => {
    setRefresh(true);

    const options = {};
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          localisation: yup.string().nullable(),
          needRegulate: yup.boolean().nullable(),
          type: yup.number().nullable(),
          subType: yup.number().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="localisation"
              label={t(translations.RadiatorForms.labels.localisation)}
              extraAttr={{ ...variant, disabled: isSupplier }}
              containerStyle={{ flex: '2' }}
              component={TextField}
            />
            <FormikInput
              name="needRegulate"
              label={t(translations.RadiatorForms.labels.needRegulate)}
              extraAttr={{
                disabled: isSupplier,
                labelPlacement: 'bottom',
                checked: values['needRegulate'],
                control: <Switch size="small" />,
              }}
              containerStyle={{
                flex: '2',
                display: 'flex',
                justifyContent: 'center',
              }}
              component={FormControlLabel}
            />
          </Box>
          <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
            <FormikInput
              name="type"
              label={t(translations.GeneratorForms.labels.type)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                options: listsOptions['heating_type'] || [],
                style: { width: '100%' },
              }}
              containerStyle={{ flex: '2' }}
              component={SimpleSelect}
            />
            <FormikInput
              name="subType"
              label={t(translations.GeneratorForms.labels.subType)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                options: listsOptions['heating_subtype'] || [],
                style: { width: '100%' },
              }}
              containerStyle={{ flex: '2' }}
              component={SimpleSelect}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
