/**
 *
 * PasswordField
 *
 */
import React from 'react';

import {
  TextField,
  OutlinedTextFieldProps,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';

interface ContexteInterface {
  showPassword: boolean;
  togglePasswordVisibility: (value: boolean) => void;
}

const ShowPasswordContext = React.createContext<ContexteInterface | undefined>(
  undefined,
);
const usePasswordVisibility = () => React.useContext(ShowPasswordContext);

export const PasswordField = (props: OutlinedTextFieldProps) => {
  const [showPassword, togglePasswordVisibility] = React.useState(false);

  return (
    <ShowPasswordContext.Provider
      value={{ showPassword, togglePasswordVisibility }}
    >
      <TextField
        type={showPassword ? 'text' : 'password'}
        {...props}
        InputProps={{
          endAdornment: <ShowPasswordAdornment />,
        }}
      />
    </ShowPasswordContext.Provider>
  );
};

const ShowPasswordAdornment = () => {
  const { showPassword, togglePasswordVisibility } = usePasswordVisibility()!;
  const toggleVisibility = () => togglePasswordVisibility(!showPassword);

  return (
    <InputAdornment position="end">
      <IconButton
        onClick={toggleVisibility}
        onMouseDown={e => e.preventDefault()}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};
