import { Statuses, PENDING, ENABLED, States } from './statuses';
import { Roles, BASIC } from './roles';

export interface UserInterface {
  id?: string;
  name?: string;
  email: string;
  emailVerified?: boolean;
  verifyEmail?: boolean;
  password?: string;
  blocked?: boolean;
  givenName: string;
  familyName: string;
  //callingCode: string;
  phoneNumber: string;
  society: string;
  lang: string;
  role: Roles;
  notifications: States[];
  // organizationId?: number;
  status: Statuses;
}

export type UserQuery = {
  [key in keyof UserInterface]?: UserInterface[key];
};

export const userInitialState: UserInterface = {
  email: '',
  givenName: '',
  familyName: '',
  phoneNumber: '',
  lang: 'fr',
  society: '',
  role: BASIC,
  notifications: [],
  status: ENABLED,
};
