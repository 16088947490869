/**
 *
 * ConfirmDialog
 *
 */
import React from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

interface Props {
  isOpen: boolean;
  cancelLabel: string;
  confirmLabel: string;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
}

export const ConfirmDialog = ({
  title,
  content,
  isOpen,
  cancelLabel,
  confirmLabel,
  onClose,
  onConfirm,
}: Props) => (
  <Dialog
    maxWidth="xs"
    aria-labelledby="confirmation-dialog-title"
    open={isOpen}
    onClose={onClose}
  >
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} color="primary">
        {cancelLabel}
      </Button>
      <Button onClick={onConfirm} color="primary">
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);
