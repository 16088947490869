/**
 *
 * SimpleSelect
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  TextFieldProps,
  MenuItem as MuiMenuItem,
  ListItem as MuiListItem,
  MenuItemProps as MuiMenuItemProps,
  ListItemProps as MuiListItemProps,
} from '@material-ui/core';

import { translations } from 'locales/i18n';

export interface MenuOption {
  value: React.ReactText;
  displayedValue?: React.ReactText;
  disabled?: boolean;
}

type Props = TextFieldProps & {
  options: MenuOption[];
  ignoreDisabling?: boolean;
  displayEmpty?: boolean;
};

export const SimpleSelect = ({
  options,
  ignoreDisabling = false,
  displayEmpty = true,
  ...props
}: Props) => {
  const { t } = useTranslation();
  return (
    <TextField
      select
      defaultValue=""
      value={props.value || null}
      {...props}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true,
      }}
      SelectProps={{
        ...props.SelectProps,
        fullWidth: true,
      }}
    >
      {displayEmpty && (
        <MenuItem key={`menu-item-${null}`} value="">
          {t(translations.SimpleSelect.none)}
        </MenuItem>
      )}
      {options.map(({ value, displayedValue, disabled }) => (
        <MenuItem
          looksDisabled={disabled}
          key={`menu-item-${value}`}
          value={value}
          disabled={disabled && !ignoreDisabling}
        >
          {displayedValue || value}
        </MenuItem>
      ))}
    </TextField>
  );
};

type MenuItemProps = MuiMenuItemProps &
  MuiListItemProps & {
    looksDisabled?: boolean;
    button?: true;
    asListItem?: boolean;
  };
export const MenuItem = styled(
  ({
    looksDisabled = false,
    asListItem = false,
    className,
    ...props
  }: MenuItemProps) => {
    const Item = asListItem ? MuiListItem : MuiMenuItem;

    return (
      <Item
        className={`${className} ${looksDisabled ? 'looks-disabled' : ''}`}
        {...(props as any)}
      />
    );
  },
)`
  transition: 0.2s all ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &.looks-disabled {
    opacity: 0.5;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }
`;
