import React from 'react';
import { Tooltip, Icon } from '@material-ui/core';
import {
  WatchLaterRounded,
  DeleteRounded,
  BlockRounded,
  CheckCircleRounded,
} from '@material-ui/icons';
import { ENABLED, DISABLED, DELETED, PENDING, Statuses } from 'types/statuses';

const icons = {
  [ENABLED]: CheckCircleRounded,
  [DISABLED]: BlockRounded,
  [DELETED]: DeleteRounded,
  [PENDING]: WatchLaterRounded,
};

interface Props {
  label: string;
  status: Statuses;
}

export const StatusIcon = ({ label, status }: Props) => (
  <Tooltip title={label} placement="bottom">
    <Icon component={icons[status]} />
  </Tooltip>
);
