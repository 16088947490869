import React from 'react';
import * as yup from 'yup';

import { Resources, ResourceStepper } from './types';
import {
  CustomerForm,
  ElectricityForm,
  GeneratorForm,
  RadiatorForm,
  RoofFrameForm,
  TechnicalVisitForm,
  EquipmentForm,
  DocumentDtForm,
  SelfConsumption,
} from './Forms';
import { translations } from 'locales/i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: JSX.Element;
  items: ResourceStepper['items'];
}

interface ContextInterface {
  validationSchema: yup.ObjectSchema<any, any, any, any>;
  setValidationSchema: (schema: yup.ObjectSchema<any, any, any, any>) => void;
  resourceForms: {
    [key in Extract<
      Resources,
      | Resources.TECHNICAL_VISIT
      | Resources.CUSTOMER
      | Resources.ROOF_FRAME
      | Resources.ELECTRICITY
      | Resources.GENERATOR
      | Resources.RADIATOR
      | Resources.EQUIPMENT_HANDLES
      | Resources.DOCUMENT_DT
      | Resources.SELF_CONSUMPTION
    >]: JSX.Element;
  };
  digitsValidation: yup.StringSchema;
  digitsValidationRequired: yup.StringSchema;
  variant: { variant: string };
  items?: ResourceStepper['items'];
}

const Context = React.createContext<ContextInterface>({} as ContextInterface);
export const useStepperContext = () => React.useContext(Context);

export const StepperContext = {
  Consumer: Context.Consumer,
  Provider: ({ children, items }: Props) => {
    const { t } = useTranslation();
    const [validationSchema, setValidationSchema] = React.useState(
      {} as yup.ObjectSchema<any, any, any, any>,
    );

    const digitsValidation = yup
      .string()
      .matches(/^[\d]+$/, t(translations.formErrors.onlyDigits));

    return (
      <Context.Provider
        value={{
          validationSchema,
          digitsValidation,
          digitsValidationRequired: digitsValidation.required(
            t(translations.formErrors.required),
          ),
          setValidationSchema,
          resourceForms: {
            [Resources.TECHNICAL_VISIT]: <TechnicalVisitForm />,
            [Resources.CUSTOMER]: <CustomerForm />,
            [Resources.ROOF_FRAME]: <RoofFrameForm />,
            [Resources.ELECTRICITY]: <ElectricityForm />,
            [Resources.GENERATOR]: <GeneratorForm />,
            [Resources.RADIATOR]: <RadiatorForm />,
            [Resources.EQUIPMENT_HANDLES]: <EquipmentForm />,
            [Resources.DOCUMENT_DT]: <DocumentDtForm />,
            [Resources.SELF_CONSUMPTION]: <SelfConsumption />,
          },
          variant: { variant: 'outlined' },
          items,
        }}
      >
        {children}
      </Context.Provider>
    );
  },
};
