import { ListInterface } from 'app/types';
import { UserInterface, Statuses } from 'types';
import { Image } from '../TechnicalVisitPage/types';

/* --- STATE --- */
export enum Resources {
  USERS = 'users',
  LISTS = 'lists',
  UNIFILAIRES = 'unifilaires',
}

export enum NonResourcesForms {
  SUBLISTS = 'sublists',
}

export interface FetchItemsParams {
  resource: Resources | NonResourcesForms;
  clear?: boolean;
  queryParams?: { [key: string]: any };
}

export interface SetItemsParams<T> {
  resource: Resources | NonResourcesForms;
  items: T[];
}

export interface AddOrUpdateItemParams<T> {
  resource: Resources | NonResourcesForms;
  item: Partial<T>;
  refreshResources?: [Resources];
  key?: string;
}

export interface SettingsPageState {
  users: UserInterface[];
  lists: ListInterface[];
  sublists: SublistInterface[];
  resourceDialogParams: ResourceDialogParams;
  unifilaires: Image[];
}

export interface ResourceDialogParams {
  title?: string;
  resource?: Resources;
  refreshResources?: [Resources];
  item?: any;
  readOnly?: boolean;
}

export interface SublistInterface {
  id?: number;
  value: string;
  status: Statuses;
  listId: number;
}

export type ContainerState = SettingsPageState;
