import styled from 'styled-components/macro';
import { ColumnVariant, SortParams } from './types';

import { TableCell } from '@material-ui/core';
import { SortIndicator } from 'react-virtualized';
import { IconButton } from '@material-ui/core';

interface Props {
  variant?: ColumnVariant;
  style: any;
  dataKey;
  sortable?: any;
  children?: any;
  isSortable?: boolean;
  sortParams?: SortParams;
  hasHorizontalScroll?: boolean;
}

export const ColumnCell = ({
  children,
  variant,
  style,
  dataKey,
  sortable,
  isSortable,
  sortParams,
  hasHorizontalScroll = false,
}: Props) => (
  <StyledTableCell
    component="div"
    variant={variant}
    style={{
      ...style,
      flexShrink: hasHorizontalScroll ? 0 : 1,
      fontWeight: variant === ColumnVariant.HEAD ? 600 : 'normal',
    }}
  >
    {children}
    {variant === ColumnVariant.HEAD && isSortable && (
      <ShiftedIconButton
        size="small"
        style={{
          backgroundColor: dataKey === sortParams?.sortBy ? '#ba6400' : '',
          color: dataKey === sortParams?.sortBy ? 'white' : '',
        }}
        onClick={(event) =>
          sortable({
            sortBy: dataKey,
            sortDirection: sortParams?.sortDirection,
          })
        }
      >
        <SortIndicator sortDirection={sortParams?.sortDirection} />
      </ShiftedIconButton>
    )}
  </StyledTableCell>
);

const StyledTableCell = styled(TableCell)`
  display: flex !important;
  flex: 1;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
`;

const ShiftedIconButton = styled(IconButton)`
  margin-left: 10px !important;
`;
