import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

import { DialogContext } from './Context';
import { FloatingSubmitBtn } from 'app/components/FloatingSubmitBtn';

import { Formik, Form } from 'formik';
import {
  Typography,
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { StandardDialog } from 'app/components/StandardDialog';
import { appDataSelector } from 'app/selectors';
import React from 'react';
import { ResourceDialogParams } from 'app/types';
import { actions } from 'app/slice';

export function UpdateDialog({
  title,
  resources,
  items,
  refreshResources,
  readOnly = false,
  isDuplicate = false,
}: ResourceDialogParams) {
  const dispatch = useDispatch();
  const { isCallingApi } = useSelector(appDataSelector);
  const {
    resourceDialogParams: { foreignKey },
  } = useSelector(appDataSelector);
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const onClose = () => {
    setActiveStep(0);
    dispatch(actions.resetResourceDialogParams(refreshResources));
  };

  const handleNext = () => {
    if (activeStep === resources!.length - 1) {
      // onClose();
      // dispatch(
      //   actions.setResourceDialogParams({
      //     title: t(translations.TechnicalVisitForms.detailsTitle),
      //     items: {
      //       [Resources.CUSTOMER]: customer,
      //       [Resources.TECHNICAL_VISIT]: technicalVisit,
      //     },
      //     resources,
      //   }),
      // );
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <DialogContext.Provider {...{ readOnly, items }}>
      {items && resources && (
        <DialogContext.Consumer>
          {({ validationSchema, resourceForms }) => (
            <StandardDialog isOpen={true} onClose={onClose}>
              <Typography variant="h6">{title}</Typography>
              <Stepper activeStep={activeStep} alternativeLabel>
                {resources.map(({ stepLabel }) => (
                  <Step key={stepLabel}>
                    <StepLabel>{stepLabel}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Formik
                enableReinitialize
                initialValues={items[resources[activeStep].resource]}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  dispatch(
                    actions.addOrUpdateItem({
                      resource: resources[activeStep].resource,
                      refreshResource: resources[activeStep].refresh,
                      isDuplicate,
                      item: {
                        ...values,
                        ...foreignKey,
                        baseUrl: `${window.location.origin}/technical-visit/`,
                      },
                    }),
                  );

                  !_.isNumber(values.id) && handleNext();
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  component={Form}
                >
                  <Box>{resourceForms[resources[activeStep].resource]}</Box>

                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      {t(translations.TechnicalVisitForms.buttons.back)}
                    </Button>
                    {!readOnly && (
                      <FloatingSubmitBtn
                        dataTestId="details-form-submit"
                        disabled={isCallingApi}
                      />
                    )}
                  </Box>
                </Box>
              </Formik>
            </StandardDialog>
          )}
        </DialogContext.Consumer>
      )}
    </DialogContext.Provider>
  );
}
